// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT USE NAVIGATE
import { useNavigate } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// IMPORT MODAL IKLAN
import Modal from '../../Layout/Modal.jsx'
// WE ULTAH
const WeUltah = () => { // BUKA KURUNG WE ULTAH
    // MEMBUAT VARIABEL WE ULTAH
    const [msg, setMsg] = useState('')
    const [kode, setKode] = useState('')
    const navigate = useNavigate()
    // PROSES MATCH KODE
    const matchWeUltahByKode = async(e)=>{
        e.preventDefault()
        try {
            await axios.post('https://server13.webew.id/matchWeUltahByKodeBE',{
                kode: kode
            })
            navigate(`/we-ultah/${kode}`, { replace: true })
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* MODAL IKLAN */}
            <Modal/>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Ultah</h2>
                                <p className='text-webew-home fw-normal'>Yuk buat ucapan ulang tahun dengan online disini, tinggal isi form, dan ucapannya sudah jadi. Setelah itu tinggal bagikan ke dia</p>
                                <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/11668440_20943572.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Vectorjuice on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* BOX WE ULTAH */}
                <section>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-lg-6'>
                                <div className='box p-4' style={{width: '100%'}}>
                                    <h3 className='text-center fw-bold'>Buat Ucapan Ultah</h3>
                                    <p className='text-webew-home fw-normal mb-3'>Silahkan Login untuk membuat ucapan ulang tahun secara online dan bagikan kepada yang sedang ulang tahun</p>
                                    <a href={'https://webew.id/login/menu-we-ultah'} className='btn btn-webew-product mb-4' aria-label='Produk webew.id'><i class="bi bi-box-arrow-in-right"></i> Login</a>
                                    <h4 className='text-center fw-bold mt-3'>Masukan Kode Ultah</h4>
                                    <p className='text-webew-home fw-normal mb-3'>Silahkan masukan kode ultah yang telah diberikan oleh seseorang dimanapun dia berada, lalu tekan tombol proses</p>
                                    <form onSubmit={matchWeUltahByKode}>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                                <input type='text' name='kode' id='kode' className='form-control form my-3' value={kode} onChange={(e) => setKode(e.target.value)} placeholder='masukan kode' required />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                                <input type='submit' className='btn btn-webew-product' style={{marginTop:'17px'}} value='Proses'/>
                                            </div>
                                        </div>
                                    </form>
                                    <h6 style={{textAlign:'center',marginTop:'10px',color:'red'}}>{msg}</h6>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>
            {/* TUTUP BOX WE ULTAH */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3'>
                                <Link to={'https://www.freepik.com/free-vector/signing-contract-official-document-agreement-deal-commitment-businessmen-cartoon-characters-shaking-hands-legal-contract-with-signature-concept-illustration_11668440.htm#page=4&position=21&from_view=author'} aria-label='Link mengarah ke pembuat image yaitu Vectorjuice di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/11668440_20943572.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Vectorjuice on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Vectorjuice on Freepik</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE ULTAH
// EKSPOR
export default WeUltah