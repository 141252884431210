// IMPORT REACT
import React, {useState} from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT LINK 
import { Link } from "react-router-dom"
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../Layout/NavbarBot.jsx'
// HOME
const Home = () => { // KURUNG BUKA HOME
    // MEMBUAT VARIABEL HOME
    const [name,] = useState('webewers')
    const [saran, setSaran] = useState('')
    // PROSES MENYIMPAN SARAN
    const createSaran = async (e) => {
        e.preventDefault()
        try{
            await axios.post('https://server13.webew.id/createSaranBE',{
                saran: saran,
                nama: name
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // PROSES LOOP PRODUK CARD BODY
    const produk = [
        {title: 'We Pernikahan',description: 'Buat undangan digital Pernikahan disini, pilih tema undangan digital, isi form data, dan undangan digital sudah jadi.',imgSrc: 'https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp',alt: 'by Pch-vector on Freepik',price: 'Rp 45.000',oldPrice: 'Rp 75.000',link: '/we-pernikahan'},
        {title: 'We Aqiqah',description: 'Buat undangan Aqiqah digital disini, pilih tema undangan digital, isi form data, dan undangan digital sudah jadi.',imgSrc: 'https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp',alt: 'by Pch-vector on Freepik',price: 'Rp 45.000',oldPrice: 'Rp 75.000',link: '/we-aqiqah'},
        {title: 'We Website',description: 'Donwload source code website dengan berbagai desain yang sesuai dengan kebutuhan kamu dengan We Website.',imgSrc: 'https://webew.id/img/25559969_7094814.webp',alt: 'by Freepik on Freepik',price: 'Rp 5.000',oldPrice: 'Rp 17.000',link: '/we-website'},
        {title: 'We CV',description: 'Kamu susah membuat CV? ayo bikin CV secara online di sini, kamu hanya perlu memilih desain dan mengisi form.',imgSrc: 'https://webew.id/img/6976378_4565.webp',alt: 'by Pch-vector on Freepik',price: 'Rp 5.000',oldPrice: 'Rp 17.000',link: '/we-cv'},
        {title: 'We Nabung',description: 'Catat pemasukan atau pengeluaran keuangan kamu dan lihat progress tabungan kamu dengan We Nabung.',imgSrc: 'https://webew.id/img/4167279_18774.webp',alt: 'by Pch-vector on Freepik',price: 'Gratis',oldPrice: '',link: '/we-nabung'},
        {title: 'We Ultah',description: 'Buat ucapan ulang tahun secara online dan berikan kepada orang tersayang, teman, atau saudara kamu dengan We Ultah.',imgSrc: 'https://webew.id/img/11668440_20943572.webp',alt: 'by Vectorjuice on Freepik',price: 'Gratis',oldPrice: '',link: '/we-ultah'},
    ]      
    // PROSES LOOP CREDIT IMAGE
    const creditImage = [
        {imgSrc: 'https://webew.id/img/credit/fun-3d-illustration-cartoon-kid-with-vr-helmet.jpg',alt: 'by Julos on Freepik',name: 'Julos On Freepik',link: 'https://www.freepik.com/free-photo/fun-3d-illustration-cartoon-kid-with-vr-helmet_14201029.htm#&position=2&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/fun-3d-illustration-cartoon-kid-with-vr-helmet (1).jpg',alt: 'by Julos on Freepik',name: 'Julos On Freepik',link: 'https://www.freepik.com/free-photo/fun-3d-illustration-cartoon-kid-with-vr-helmet_14201016.htm#&position=7&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/fun-cartoon-kid-with-rain-gear.jpg',alt: 'by Julos on Freepik',name: 'Julos On Freepik',link: 'https://www.freepik.com/free-photo/fun-cartoon-kid-with-rain-gear_14200919.htm#&position=13&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp',alt: 'by Pch-vector on Freepik',name: 'Pch-vector on Freepik',link: 'https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/6976378_4565.webp',alt: 'by Pch-vector on Freepik',name: 'Pch-vector on Freepik',link: 'https://www.freepik.com/free-vector/recruit-agent-analyzing-candidates_6976378.htm#page=4&position=14&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/25559969_7094814.webp',alt: 'by Freepik on Freepik',name: 'Freepik on Freepik',link: 'https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author'},
        {imgSrc: 'https://webew.id/img/credit/4167279_18774.webp',alt: 'by katemangostar on Freepik',name: 'katemangostar on Freepik',link: 'https://www.freepik.com/free-vector/marketing-group-working-business-solution-flat-icon_4167279.htm#from_view=detail_alsolike'},
        {imgSrc: 'https://webew.id/img/credit/11668440_20943572.webp',alt: 'by Vectorjuice on Freepik',name: 'Vectorjuice on Freepik',link: 'https://www.freepik.com/free-vector/signing-contract-official-document-agreement-deal-commitment-businessmen-cartoon-characters-shaking-hands-legal-contract-with-signature-concept-illustration_11668440.htm#page=4&position=21&from_view=author'},
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* HOME */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-4'>
                                <h1 className='title-webew-home'>webew.id</h1>
                                <p className='text-center mb-4'>Selalu ada dan selalu siap membantu wujudkan kebutuhan dan keinginan anda dengan berbagai aplikasi di dalam website ini</p>
                                <a href="/#produk" className='btn btn-webew-product' aria-label='Produk webew.id'>Produk Kami</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/fun-3d-illustration-cartoon-kid-with-vr-helmet.jpg' className='img-fluid img-webew-home mx-auto d-block' width='480px' alt='by julos on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP HOME */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* ABOUT */}
                <section id='about' style={{marginTop:'-70px'}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <img src='https://webew.id/img/fun-3d-illustration-cartoon-kid-with-vr-helmet (1).jpg' className='img-fluid mx-auto d-block' width='480px' alt='by julos on freepik'/>
                            </div>
                            <div className='col-12 col-md-6'>
                                <h2 className='judul-about px-4'>Layanan Terbaik</h2>
                                <p className='px-4'>Kami akan memberikan beberapa layanan aplikasi terbaik yang kami miliki kepada kamu. Mulai dari Undangan DIgital, jasa pembuatan Website, pembuatan CV secara online, dan penyedia ucapan ulang tahun secara online.</p>
                            </div>
                        </div>
                        <div className='row mt-5 pt-5'>
                            <div className='col-12 col-md-6 order-1 order-md-0'>
                                <h2 className='judul-about px-4'>Harganya Murah</h2>
                                <p className='px-4'>Tidak perlu khawatir untuk memikirkan harga, karena disini kami memberikan harga yang sangat ramah di kantong. Dan jangan khawatir juga dengan kualitasnya, karna kami akan menjaga kualitas agar tetap menjadi nomer satu.</p>
                            </div>
                            <div className='col-12 col-md-6 order-0 order-md-1'>
                                <img src='https://webew.id/img/fun-cartoon-kid-with-rain-gear.jpg' className='img-fluid mx-auto d-block' width='480px' alt='by julos on freepik' style={{transform: 'scalex(-1)'}}/>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP ABOUT */}
            {/* PRODUK */}
                <section id='produk'>
                    <div className='container'>
                        <div className='row'>
                        {produk.map((product, index) => (
                            <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                <a href={product.link} aria-label='Produk Webew.id'>
                                    <div className='card mb-4 pt-4 pb-2 px-1' style={{ width: '100%' }}>
                                        <img src={product.imgSrc} className='card-img-top' style={{ width: '100%' }} alt={product.alt}/>
                                        <div className='card-body'>
                                            <h5 className='text-center fw-bold'>{product.title}</h5>
                                            <p className='card-text fw-normal mt-3'>{product.description}</p>
                                            <p className='card-text-harga fw-bold'>Harga Saat Ini</p>
                                            <p className='card-text-rupiah mb-0'>{product.price} <s>{product.oldPrice}</s></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP PRODUK */}
            {/* SARAN */}
                <section id='saran' className='mb-5'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-7">
                                <div className='box p-3 mt-4' style={{width: '100%',minHeight:'100px'}}>
                                    <form onSubmit={createSaran} className='mt-3'>
                                        <div className='container'>
                                            <div className='row'>
                                                <h6 className='card-title text-center mb-3'>Beri saran untuk kemajuan webew</h6>
                                                <div className='col-12 col-md-6'>
                                                    <input type='text' className='form-control form mb-3' value={saran} onChange={(e)=> setSaran(e.target.value)} required placeholder='Beri saran'/>
                                                    <input type='text' className='form-disable form mb-3' value={name} readOnly/>
                                                </div>
                                                <div className='col-12 col-md-6 mb-2'>
                                                    <input type='submit' className='btn btn-webew-login mb-2' value='Kirim Saran'/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP SARAN */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px',marginTop:'0px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row'>
                            {creditImage.map((credit, index) => (
                                <div key={index} className='col-6 col-md-4 mb-3'>
                                <Link to={credit.link} aria-label={`Link mengarah ke pembuat image yaitu ${credit.name}`}>
                                    <div className='card' style={{ width: '100%', minHeight: '180px' }}>
                                        <img src={credit.imgSrc} className='mx-auto d-block img-fluid pt-3' style={{ maxHeight: '130px' }}alt={credit.alt}/>
                                        <p className='text-center'>{credit.name}</p>
                                    </div>
                                </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP HOME
// EKSPOR
export default Home