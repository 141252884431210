// IMPORT REACT
import React from 'react';

// FOOTER
const Footer = () => {
    return (
        <footer>
            <div className='container mb-5 pb-2'>
                <p className='copyright text-center fw-semibold' style={{ color: '#d1d1d1', fontSize: '9pt' }}>
                    &copy; {new Date().getFullYear()} webew.id. All Rights Reserved<a href='https://www.privacypolicyonline.com/live.php?token=HjdimFn29DuNIXNZzYRRZgjPZVt6jarW' className='fw-bold' style={{ color: '#d1d1d1', fontSize: '9pt' }}target="_blank"rel="noopener noreferrer">. Privacy Policy</a>
                </p>
            </div>
        </footer>
    );
};

// EKSPOR
export default Footer;
