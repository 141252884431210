// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from "react-router-dom"
// MENU NAVBAR BOTTOM
const MenuNavbarBotAdmin = () => { // KURUNG BUKA MENU NAVBAR BOTTOM
    // MEMBUAT VARIABEL PANGGILAN
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        } catch (error) {
            if(error.response){
                navigate("/lowemin", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logWeMinBE')
            navigate("/lowemin", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className="navbot">
                <div className="container fixed-bottom" style={{width:"100%",height:"auto",background:"white", padding:"10px 15px 10px 15px",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",boxShadow: "0 -15px 18px 5px rgba(211, 215, 241, 0.507)"}}>
                    <div className="row">
                        <div className="col-4"><a href={`/dashMin/${panggilan}`} className="btn btn-webew-product" style={{padding:"4px 0 4px 0",marginTop:"3px"}} aria-label="Produk webew.id"><i className="bi bi-house-up" style={{color:"white"}}></i></a></div>
                        <div className="col-4">
                            <div className="dropdown-center">
                                <button className="btn btn-webew-product" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{padding:"7px 0 7px 0"}}><i className="bi bi-grid-fill" style={{color:"white"}}></i></button>
                                <ul className="dropdown-menu mb-3">
                                    <li><a href={`/menuAdminWeSaran/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Saran" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Saran</a></li>
                                    <li><a href={`/menuAdminWeUsers/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Users" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Users</a></li>
                                    <li><a href={`/menuAdminWePernikahan/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Pernikahan" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Pernikahan</a></li>
                                    <li><a href={`/menuAdminWeAqiqah/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Aqiqah" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Aqiqah</a></li>
                                    <li><a href={`/menuAdminWeCv/${panggilan}`} className="dropdown-item fw-normal" aria-label="We CV" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We CV</a></li>
                                    <li><a href={`/menuAdminWeNabung/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Nabung" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Nabung</a></li>
                                    <li><a href={`/menuAdminWeUltah/${panggilan}`} className="dropdown-item fw-normal" aria-label="We Ultah" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Ultah</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-4"><button onClick={Logout} className="btn btn-webew-product" style={{padding:"4px 0 4px 0",marginTop:"3px"}} aria-label="Produk webew.id"><i className="bi bi-power" style={{color:"white"}}></i></button></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR BOTTOM
// EKSPOR
export default MenuNavbarBotAdmin