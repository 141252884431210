// IMPORT REACT
import React, {useState,useRef} from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE AQIQAH
const WeAqiqah = () => { // BUKA KURUNG WE AQIQAH
    const [selectedViewLink, setSelectedViewLink] = useState("");
    const modalRef = useRef(null);
    const handleBuatClick = (viewLink) => {
        setSelectedViewLink(viewLink);
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
    };
    // PROSES LOOP JENIS AQIQAH CARD BODY
    const jenisWeAqiqah = [
        { nama: 'White I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white1.png', viewLink: '/we-aqiqah/whiteI', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'White II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white2.png', viewLink: '/we-aqiqah/whiteII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'White III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white3.png', viewLink: '/we-aqiqah/whiteIII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'White IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/white4.png', viewLink: '/we-aqiqah/whiteIV', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Flower I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower1.png', viewLink: '/we-aqiqah/flowerI', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Flower II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower2.png', viewLink: '/we-aqiqah/flowerII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Flower III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower3.png', viewLink: '/we-aqiqah/flowerIII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Flower IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/flower4.png', viewLink: '/we-aqiqah/flowerIV', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Color I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color1.png', viewLink: '/we-aqiqah/colorI', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Color II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color2.png', viewLink: '/we-aqiqah/colorII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'Color III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color3.png', viewLink: '/we-aqiqah/colorIII', createLink: 'https://webew.id/login/menu-we-aqiqah' },
        { nama: 'ColorIV4', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/color4.png', viewLink: '/we-aqiqah/colorIV', createLink: 'https://webew.id/login/menu-we-aqiqah' },
    ];
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Aqiqah</h2>
                                <p className='text-webew-home fw-normal'>Buat undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <a href={'/'} className='btn btn-webew-product' aria-label='Kembali webew.id'><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE AQIQAH */}
                <section>
                    <div className='container'>
                        <div className='row'>
                            {jenisWeAqiqah.map((data, index) => (
                                <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                    <button onClick={() => handleBuatClick(data.viewLink)} className='card mb-4 p-4' style={{ width: '100%' }}>
                                        <div className="row justify-content-center text-center mx-auto d-block">
                                            <div className="col-12 mb-2">
                                                <h5 className='fw-bold text-center'>{data.nama}</h5>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <img src={data.imgSrc} className='mx-auto d-block img-fluid' style={{ maxHeight: '450px' }} alt={data.alt} />
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                         {/* Modal Bootstrap */}
                         <div className="modal fade" id="buatModal" ref={modalRef} tabIndex="-1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center p-4">
                                        <div className="row">
                                            <div className="col-12 my-4">
                                                <button className='btn btn-webew-product py-2' onClick={() => window.location.href = `${selectedViewLink}`}>Lihat Contoh Undangan</button>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <button className='btn btn-webew-product py-2' onClick={() => window.location.href = `https://webew.id/login/menu-we-pernikahan`}>Buat Undangan Sendiri</button>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <button type="button" className="btn btn-logout mx-auto py-2 px-4" data-bs-dismiss="modal" aria-label="Close">Batal</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE AQIQAH */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <Link to={'https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author'} aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE AQIQAH
// EKSPOR
export default WeAqiqah