// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// MENU NAVBAR BOTTOM
const MenuNavbarBot = () => { // KURUNG BUKA MENU NAVBAR BOTTOM
    // PARAMS
    const {panggilanParams} = useParams()
    // MEMBUAT VARIABEL URL FOTO
    const [urlFoto, setUrlFoto] = useState("")
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        selectWeUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // LOGOUT
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/logoutBE')
            navigate("/login/dashboard", { replace: true })
        } catch (error) {
            console.log(error)
        }
    }
    // MENAMPILKAN WE USERS BY PARAMS PANGGILAN
    const selectWeUsers = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUsersBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                console.log(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div>
            {/* NAVBAR BOTTOM */}
            <div className="navbot">
                <div className="container fixed-bottom" style={{width:"100%",height:"auto",background:"white", padding:"5px 0 10px 0",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",boxShadow: "0 -15px 18px 5px rgba(211, 215, 241, 0.507)"}}>
                    <div className="row justify-content-center">
                        <div className="col-2 d-flex justify-content-center"><a href={`/dashboard/${panggilanParams}`} className="btn btn-webew-product logout-btn" style={{width:"auto",padding:"0px 8px 2px 8px",marginTop:"3px", position: "relative"}} aria-label="Produk webew.id"><i className="bi bi-house" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Dashboard</span></a></div>
                        <div className="col-2 d-flex justify-content-center">
                            <div className="dropdown-center">
                                <button className="btn btn-webew-product logout-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width:"auto",padding:"0px 8px 2px 8px",marginTop:"3px", position: "relative"}}><i className="bi bi-grid" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Menu Aplikasi</span></button>
                                <ul className="dropdown-menu mb-3">
                                    <li><a href={`/menu-we-pernikahan/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We Pernikahan" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Pernikahan</a></li>
                                    <li><a href={`/menu-we-aqiqah/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We Aqiqah" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Aqiqah</a></li>
                                    <li><a href={`/menu-we-website/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We Website" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Website</a></li>
                                    <li><a href={`/menu-we-cv/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We CV" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We CV</a></li>
                                    <li><a href={`/menu-we-nabung/${panggilanParams}/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We Motivasi" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Nabung</a></li>
                                    <li><a href={`/menu-we-ultah/${panggilanParams}`} className="dropdown-item fw-normal" aria-label="We Ultah" style={{fontSize:"13pt"}}><i className="bi bi-grid"></i> Menu We Ultah</a></li>
                                    <li><a className='dropdown-item fw-normal' href={'https://api.whatsapp.com/send?phone=6287788362272/'} aria-label='Whatsapp' style={{fontSize:'13pt'}}><i className="bi bi-whatsapp"></i> WhatsApp</a></li>
                                    <li><a className='dropdown-item fw-normal' href={'https://www.instagram.com/webew.id/'} aria-label='Instagram' style={{fontSize:'13pt'}}><i className="bi bi-instagram"></i> Instagram</a></li>
                                    <li><a className='dropdown-item fw-normal' href={'https://www.tiktok.com/@fish_call'} aria-label='Tiktok' style={{fontSize:'13pt'}}><i className="bi bi-tiktok"></i> Tiktok</a></li>
                                    <li><a className='dropdown-item fw-normal' href={'http://www.youtube.com/@webewid'} aria-label='youtube' style={{fontSize:'13pt'}}><i className="bi bi-youtube"></i> Youtube</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            { urlFoto === null
                                ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{marginTop:"3px",minWidth:'42px',maxWidth:'42px',minHeight:"42px",maxHeight:"42px",borderRadius:'50%'}}/>
                                : <img src={"https://server13.webew.id/images/"+urlFoto} className='mx-auto d-block' alt='foto' style={{marginTop:"3px",minWidth:'42px',maxWidth:'42px',minHeight:"42px",maxHeight:"42px",borderRadius:'50%'}}/>
                            }
                        </div>
                        <div className="col-2 d-flex justify-content-center"><button className='btn btn-webew-product logout-btn mx-auto d-block' data-bs-toggle='modal' data-bs-target='#staticBackdropDonasi' style={{width:"auto",padding:"0px 8px 2px 8px",marginTop:"3px", position:"relative"}} aria-label="Donasi webew.id"><i className='bi bi-coin'style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Donasi</span></button></div>
                        <div className="col-2 d-flex justify-content-center"><button onClick={Logout} className="btn btn-webew-product logout-btn" style={{width:"auto",padding:"0px 8px 2px 8px",marginTop:"3px", position:"relative"}} aria-label="Produk webew.id"><i className="bi bi-power" style={{fontSize:"20pt",color:"white"}}></i><span className="logout-text">Logout</span></button></div>
                    </div>
                </div>
            </div>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU NAVBAR BOTTOM
// EKSPOR
export default MenuNavbarBot