// IMPORT REACT
import React,{ useRef, useState, useEffect } from "react"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// WE PERNIKAHAN CONTOH
const WePernikahanContoh = () => { // KURUNG BUKA WE PERNIKAHAN CONTOH
    // MEMBUAT VARIABEL WE PERNIKAHAN CONTOH
    const [pajangan, setPajangan] = useState('')
    const [btnColorCD, setBtnColorCD] = useState('')
    const [btnColor, setBtnColor] = useState('')
    const [fntColor, setFntColor] = useState('')
    const [borderBtn, setBorderBtn] = useState('')
    const [borderBoxLeft, setBorderBoxLeft] = useState('none')
    const [borderBoxRight, setBorderBoxRight] = useState('none')
    const [bgCover, setBgCover] = useState('')
    const [bgImageCover, setBgImageCover] = useState('')
    const [bgImageTop, setBgImageTop] = useState('')
    const [bgColor, setBgColor] = useState('')
    const [bgImageBox1, setBgImageBox1] = useState('')
    const [imagePengantin1, setImagePengantin1] = useState('')
    const [imagePengantin2, setImagePengantin2] = useState('')
    const [namaPengantin1, setNamaPengantin1] = useState('')
    const [namaInstagramPengantin1, setNamaInstagramPengantin1] = useState('')
    const [namaPengantin2, setNamaPengantin2] = useState('')
    const [namaInstagramPengantin2, setNamaInstagramPengantin2] = useState('')
    const [urutanPengantin1, setUrutanPengantin1] = useState('')
    const [urutanPengantin2, setUrutanPengantin2] = useState('')
    const [namaBapakPengantin1, setNamaBapakPengantin1] = useState('')
    const [namaBapakPengantin2, setNamaBapakPengantin2] = useState('')
    const [namaIbuPengantin1, setNamaIbuPengantin1] = useState('')
    const [namaIbuPengantin2, setNamaIbuPengantin2] = useState('')
    const [bgColorBox2, setBgColorBox2] = useState('')
    const [hariAkad, setHariAkad] = useState('')
    const [tanggalAkad, setTanggalAkad] = useState('')
    const [jamMulaiAkad, setJamMulaiAkad] = useState('')
    const [jamAkhirAkad, setJamAkhirAkad] = useState('')
    const [hariResepsi, setHariResepsi] = useState('')
    const [tanggalResepsi, setTanggalResepsi] = useState('')
    const [jamMulaiResepsi, setJamMulaiResepsi] = useState('')
    const [jamAkhirResepsi, setJamAkhirResepsi] = useState('')
    const [zonaWaktu, setZonaWaktu] = useState('')
    const [bgImageBox2, setBgImageBox2] = useState('')
    const [alamatAkad, setAlamatAkad] = useState('')
    const [mapsAkad, setMapsAkad] = useState('')
    const [alamatResepsi, setAlamatResepsi] = useState('')
    const [mapsResepsi, setMapsResepsi] = useState('')
    const [ayat, setAyat] = useState('')
    const [surah, setSurah] = useState('')
    const [imageGallery1, setImageGallery1] = useState('')
    const [imageGallery2, setImageGallery2] = useState('')
    const [imageGallery3, setImageGallery3] = useState('')
    const [imageGallery4, setImageGallery4] = useState('')
    const [imageGallery5, setImageGallery5] = useState('')
    const [imageGallery6, setImageGallery6] = useState('')
    const [namaBank1, setNamaBank1] = useState('')
    const [namaPemilikBank1, setNamaPemilikBank1] = useState('')
    const [norekBank1, setNorekBank1] = useState('')
    const [namaBank2, setNamaBank2] = useState('')
    const [namaPemilikBank2, setNamaPemilikBank2] = useState('')
    const [norekBank2, setNorekBank2] = useState('')
    const [rotate, setRotate] = useState('')
    const [pemilik1, setPemilik1] = useState('')
    const [link1, setLink1] = useState('')
    const [pemilik2, setPemilik2] = useState('')
    const [link2, setLink2] = useState('')
    const [pemilik3, setPemilik3] = useState('')
    const [link3, setLink3] = useState('')
    const [pemilik4, setPemilik4] = useState('')
    const [link4, setLink4] = useState('')
    const [bukaUndangan, setBukaUndangan] = useState('none')
    const [tutupButton, setTutupButton] = useState('')
    const [isPlaying, setIsPlaying] = useState(false);
    const { jenisWePernikahanParams } = useParams();
    const audioRef = useRef(null);
    const elementsRef = useRef([]);
    // USEEFFECT
    useEffect(() => {
        if(jenisWePernikahanParams === "agapanthus"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_agapanthus.png")
            setBtnColorCD("#0081b0")
            setBtnColor("#f3fbff")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #0081b0")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#4fd0ff15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "aglaonema"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_aglaonema.png")
            setBtnColorCD("green")
            setBtnColor("#f4fff3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid green")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fafff82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#5eff4f15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph")
            setPemilik2("pikisuperstar")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "alamanda"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_alamanda.png")
            setBtnColorCD("#b05500")
            setBtnColor("#fffaf3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b05500")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fffcf82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "amaryllis"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_amaryllis.png")
            setBtnColorCD("#b00000")
            setBtnColor("#fff3f3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b00000")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fff8f82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ff4f4f15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais")
            setPemilik1("freepik")
            setLink2("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "adorable"){
            setBtnColorCD("#00b07e")
            setBtnColor("#f3fff8")
            setFntColor("rgb(0, 58, 41)")
            setBorderBtn("3px solid #00b07e")
            setBgCover("linear-gradient(to bottom,rgb(221, 255, 237),rgb(221, 255, 237)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#f8fffd2f")
            // Box 1
            setBgImageBox1("")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#4fffc115")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "cute"){
            setBtnColorCD("rgb(176, 0, 135)")
            setBtnColor("#fff3fe")
            setFntColor("rgb(61, 0, 47)")
            setBorderBtn("3px solid rgb(176, 0, 135)")
            setBgCover("linear-gradient(to bottom, #f1c9fe,rgb(245, 214, 255)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fff8fc2f")
            // Box 1
            setBgImageBox1("")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ff4fb915")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "beauty"){
            setBtnColorCD("rgb(176, 59, 0)")
            setBtnColor("#fff7f3")
            setFntColor("rgb(68, 26, 0)")
            setBorderBtn("3px solid rgb(176, 59, 0)")
            setBgCover("linear-gradient(to bottom, #fed5c9,rgb(255, 225, 215)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fffbf82f")
            // Box 1
            setBgImageBox1("")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "cool"){
            setBtnColorCD("rgb(0, 147, 176)")
            setBtnColor("#f3fcff")
            setFntColor("rgb(0, 46, 55)")
            setBorderBtn("3px solid rgb(0, 147, 176)")
            setBgCover("linear-gradient(to bottom, rgb(212, 240, 255),rgb(212, 240, 255)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#4fdcff15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blegold"){
            setBtnColorCD("rgb(176, 135, 0)")
            setBtnColor("#392f00")
            setFntColor("rgb(255, 220, 80)")
            setBorderBtn("3px solid rgb(176, 135, 0)")
            setBorderBoxLeft("3px solid rgb(255, 204, 0)")
            setBorderBoxRight("3px solid rgb(255, 204, 0)")
            setBgCover("linear-gradient(to bottom,rgb(70, 61, 0),rgb(0, 0, 0)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fffef82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ffed4f15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blepink"){
            setBtnColorCD("rgb(255, 44, 209)")
            setBtnColor("rgb(67, 0, 62)")
            setFntColor("rgb(255, 169, 249)")
            setBorderBtn("3px solid rgb(255, 190, 237)")
            setBorderBoxLeft("3px solid rgb(255, 190, 237)")
            setBorderBoxRight("3px solid rgb(255, 190, 237)")
            setBgCover("linear-gradient(to bottom,rgb(74, 0, 77),rgb(0, 0, 0)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#fff8fd2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ff4fd015")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bletosca"){
            setBtnColorCD("rgb(0, 130, 133)")
            setBtnColor("#004748")
            setFntColor("rgb(185, 254, 255)")
            setBorderBtn("3px solid rgb(0, 251, 255)")
            setBorderBoxLeft("3px solid rgb(0, 247, 255)")
            setBorderBoxRight("3px solid rgb(0, 247, 255)")
            setBgCover("linear-gradient(to bottom,rgb(0, 62, 64),rgb(0, 0, 0)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#4ffcff15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blewhite"){
            setBtnColorCD("rgb(0, 0, 0)")
            setBtnColor("rgb(73, 73, 73)")
            setFntColor("rgb(255, 255, 255)")
            setBorderBtn("3px solid rgb(255, 255, 255)")
            setBorderBoxLeft("3px solid rgb(255, 255, 255)")
            setBorderBoxRight("3px solid rgb(255, 255, 255)")
            setBgCover("linear-gradient(to bottom,rgb(76, 76, 76),rgb(0, 0, 0)")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#ffffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#ffffff15")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnwI"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#43434315")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnwII"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#43434315")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnwIII"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#43434315")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnwIV"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            setBgImageCover("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Cover Top
            setBgImageTop("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')")
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png")
            setImagePengantin1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImagePengantin2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setNamaPengantin1("Romeo Romeano")
            setNamaInstagramPengantin1("romeo_")
            setNamaInstagramPengantin2("juliet12")
            setNamaPengantin2("Juliet Julieto")
            setUrutanPengantin1("pertama")
            setUrutanPengantin2("kedua")
            setNamaBapakPengantin1("Abdul Aziz")
            setNamaBapakPengantin2("Aziz Abdul")
            setNamaIbuPengantin1("Nuraini")
            setNamaIbuPengantin2("Aininuroh")
            // Box 2
            setBgColorBox2("#43434315")
            setHariAkad("Sabtu")
            setTanggalAkad("13 Mei 2022")
            setJamMulaiAkad("08.00")
            setJamAkhirAkad("11.00")
            setHariResepsi("Sabtu")
            setTanggalResepsi("13 Mei 2022")
            setJamMulaiResepsi("11.00")
            setJamAkhirResepsi("17.00")
            setZonaWaktu("WIB")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png")
            // Box 3
            setAlamatAkad("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsAkad("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            setAlamatResepsi("Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia")
            setMapsResepsi("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126750.42681257085!2d106.68943050938026!3d-6.229728402580532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6991a673adf3d1%3A0xf6dc3e7bcd98a0d4!2sJakarta%2C%20Indonesia!5e0!3m2!1sen!2sid!4v1633416158972!5m2!1sen!2sid")
            // Box 4
            setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
            setSurah("QS. Ar-Rum ayat 21")
            // Box 5
            setImageGallery1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg")
            setImageGallery2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg")
            setImageGallery3("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg")
            setImageGallery4("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg")
            setImageGallery5("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg")
            setImageGallery6("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg")
            // Box 6
            setNamaBank1("Mandiri")
            setNamaPemilikBank1("Romeo")
            setNorekBank1("12345679292929292")
            setNamaBank2("BCA")
            setNamaPemilikBank2("Juliet")
            setNorekBank2("12345679292929292")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            setPemilik4("The Way You Look Me - Christian Bautista")
            setRotate("rotate(-180deg)")
        }
        // animasi scroll
        if (!Array.isArray(elementsRef.current[0])) {
            elementsRef.current[0] = []; // Pastikan current[0] diinisialisasi sebagai array
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const element = entry.target;
                    animateElement(element); // Panggil fungsi untuk menganimasikan elemen
                }
                });
            },
            {
                threshold: 0, // Elemen terlihat 10% sebelum animasi dijalankan
                rootMargin: "0px 0px 0px 0px",
            }
        );
        elementsRef.current[0].forEach((el) => observer.observe(el)); // Iterasi semua elemen di current[0]
        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // ANIMASI SCROLL
    const animateElement = (element) => {
        let start = null;

        const duration = 1200; // Durasi animasi dalam milidetik
        const initialScale = 0.5; // Ukuran awal (kecil)
        const targetScale = 1; // Ukuran akhir (normal)
        const initialOpacity = 0; // Transparan awal
        const targetOpacity = 1; // Transparan akhir
    
        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = Math.min((timestamp - start) / duration, 1); // Hitung progres animasi (0 ke 1)
    
          // Lerp (Linear Interpolation) untuk scale dan opacity
          const currentScale = initialScale + (targetScale - initialScale) * progress;
          const currentOpacity = initialOpacity + (targetOpacity - initialOpacity) * progress;
    
          // Atur gaya elemen
          element.style.transform = `scale(${currentScale})`;
          element.style.opacity = currentOpacity;
    
          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
    };
    // BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        setBukaUndangan("")
        setTutupButton("none")
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PLAY MUSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    // BUTTON PLAY PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    // CLASSNAME PAJANGAN 
    const kupuKupu = [
        "moving-image-1",
        "moving-image-2",
        "moving-image-3",
        "moving-image-4",
        "moving-image-5",
    ];
    const daun = [
        "moving-image-6",
        "moving-image-7",
        "moving-image-8",
        "moving-image-9",
        "moving-image-10",
    ];
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className="background-home">
             <section>
                {/* COVER UNDANGAN */}
                <div style={{position:"fixed",minHeight:"100vh",width:"100%",background: `linear-gradient(to top, ${btnColorCD} -100%, ${bgColorBox2}) 100%`,zIndex:"1",display:tutupButton,overflow:"auto"}}>
                    <div className="container-fluid">
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh",width:"100%",zIndex:"2"}}>
                            <div className="px-4 py-4" style={{backgroundImage:[bgCover],height:"auto",width:"auto",borderRadius:"20px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                <img src={bgImageCover} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:`0px 10px 25px -14px ${btnColorCD}`}} alt="pengantin" />
                                <h5 className='fw-bold bad-script-regular text-center' style={{marginTop:"30px",color:[fntColor]}}>Kepada Yth. Bpk/Ibu/Saudara/i</h5>
                                <p className='fw-normal courgette-regular m-0 text-center' style={{color:[fntColor],fontSize:"40pt"}}>Fiscal</p>
                                <button onClick={btnBukaUndangan} className="form form-control fw-bold mx-auto d-block mt-2 px-4 bad-script-regular" style={{width:"auto",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],backgroundColor:[btnColor],display:tutupButton,border:[borderBtn],fontSize:"13pt"}}><i className="bi bi-envelope-paper-heart"></i> Buka Undangan</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* AUDIO */}
                <div className="fixed-bottom" style={{display:bukaUndangan}}>
                    <audio ref={audioRef} src="/song/The Way You Look Me - Christian Bautista.mp3" autoPlay loop preload='none'/>
                    <button onClick={handlePlayPause} style={{width:"100%",height:"auto",border:"none",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderTop:[borderBtn]}}>
                        {isPlaying ? <p style={{color:[fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-pause-circle" style={{fontSize:"18px"}}></i> {pemilik4} </p>:<p style={{color:[fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-play-circle" style={{fontSize:"18px"}}></i> {pemilik4}</p>}                                           
                    </button>
                </div>
                {/* COVER */}
                <section style={{overflow:"auto",height:"100vh"}}>
                    <section style={{backgroundImage:`${bgImageTop}`,width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{background: `linear-gradient(to top, ${btnColorCD} -100%, ${bgColor}) 100%`,width:"100%",height:"100vh",display:[bukaUndangan]}}>
                            <div className="marginTopWePernikahan" style={{width:"100%",marginBottom:"18px",textAlign:"center",textShadow:`0 0 20px ${btnColorCD}`}}>
                                <p className='fw-bold m-0 courgette-regular' style={{color:"white",fontSize:"40pt"}}>Romeo & Juliet</p>
                                <h2 className='fw-bold bad-script-regular' style={{color:"white",fontVariant:"small-caps", marginTop:"-10px"}}>Sabtu, 13 Januari 2022</h2>
                            </div>
                            <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                <table style={{ textAlign: "center", width: "100%" }}>
                                    <tbody>
                                        <tr style={{ textAlign: "center", width: "100%" }}>
                                            <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                            </td>
                                            <td style={{ width: "2%" }}></td>
                                            <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                            </td>
                                            <td style={{ width: "2%" }}></td>
                                            <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                            </td>
                                            <td style={{ width: "2%" }}></td>
                                            <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                    <section style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <p className='text-center fw-bold m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontFamily:"initial",fontSize:"23pt"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</p>
                            <h6 className='text-center fw-normal m-0 mb-2 bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tbody>
                                    <tr>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"45%"}}><img src={imagePengantin1} className='img-fluid'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`10px 10px 25px -18px ${btnColorCD}`,borderLeft:[borderBoxLeft]}} alt="calon pengantin pria" /></td>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"5%"}}><div className="text-center bi bi-suit-heart-fill"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25px",color:[fntColor]}}></div></td>
                                        <td ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"45%"}}><img src={imagePengantin2} className='img-fluid'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`-10px 10px 25px -18px ${btnColorCD}`,borderRight:[borderBoxRight]}} alt="calon pengantin Wanita" /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt"}}>{namaPengantin1}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-50'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i> {namaInstagramPengantin1}</h6>
                                        <h6 className='text-center fw-normal bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Putra {urutanPengantin1} dari<br/>Bapak {namaBapakPengantin1} & Ibu {namaIbuPengantin1}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center fw-normal courgette-regular mt-2"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25pt",color:[fntColor]}}>&</div></div>
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt"}}>{namaPengantin2}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-50'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i> {namaInstagramPengantin2} </h6>
                                        <h6 className='text-center fw-normal bad-script-regular'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Putri {urutanPengantin2} dari<br/>Bapak {namaBapakPengantin2} & Ibu {namaIbuPengantin2}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100'/>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="px-3 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Akad Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{hariAkad}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{tanggalAkad}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{jamMulaiAkad} sd {jamAkhirAkad} {zonaWaktu}</h6>
                                                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="33 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Resepsi Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{hariResepsi}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{tanggalResepsi}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{jamMulaiResepsi} sd {jamAkhirResepsi} {zonaWaktu}</h6>
                                                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City" aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <div className='bi bi-map text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                            <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Alamat Nikah</p>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Akad Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{alamatAkad}</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Resepsi Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{alamatResepsi}</h6>
                                        <a href="https://www.google.com" aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100'/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <h6 className='text-center fw-normal mt-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{ayat}</h6>
                                                    <h2 className='text-center fw-bold pt-1 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>({surah})</h2>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                        <p className='text-center fw-bold pt-0 mb-4 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Galeri Foto</p>
                                        <div className="container-fluid">
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href={imageGallery1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery1} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery2} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery3} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href={imageGallery4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery4} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery5} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery6} className="img-fluid" width="100%" alt="Foto"  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100' />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center py-4 px-2">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-credit-card text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Kado Pernikahan</p>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {namaBank1}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[fntColor],fontSize:"12pt"}}>An. {namaPemilikBank1}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontSize:"14pt"}} value={norekBank1}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontSize:"12pt"}}><i className="bi bi-copy fw-bold"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {namaBank2}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[fntColor]}}>An. {namaPemilikBank2}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontSize:"14pt"}} value={norekBank2}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor]}}><i className="bi bi-copy fw-bold"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Beri Ucapan</p>
                                                    <form ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)"}}>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="nama" style={{color:[fntColor],fontSize:"13pt"}}>Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2 bad-script-regular' required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} maxLength="100" />
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="status"style={{color:[fntColor],fontSize:"13pt"}}>Status</label>
                                                        <select className="form-select form mb-2 bad-script-regular" id="status" name='status' aria-label="status" required style={{boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="ucapan"style={{color:[fntColor],fontSize:"13pt"}}>Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4 bad-script-regular' required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} maxLength="300"/>
                                                        <button type="submit"  className="form form-control fw-bold mb-4 mt-5 bad-script-regular" style={{backgroundColor:[btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"15pt"}}><i className="bi bi-send"></i> Kirim</button>
                                                    </form>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Ucapan Live</p>
                                                    <div style={{maxHeight:"200px",overflow:"auto"}}>
                                                        <table>
                                                            <tbody>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Akang</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Halo, selamat wedding ya, semoga samawa</p></td>
                                                                </tr>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Agro</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Asik nikah</p></td>
                                                                </tr>
                                                                <tr className='mt-2' >
                                                                    <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[fntColor],fontSize:"20px"}}></i></p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>Kolah</p></td>
                                                                    <td style={{width:"1%"}}><p> </p></td>
                                                                    <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>Selamat menempuh hidup baru</p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <p className='text-center fw-bold mt-4 mb-5 courgette-regular' style={{color:[fntColor],fontSize:"25pt"}}>Website By Webew.id</p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[fntColor],marginBottom:"-2px"}}>Image On Freepik</h3>
                            <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                                <a href={link1} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik1}</a>
                                <a href={link2} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik2}</a>
                                <a href={link3} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik3}</a>
                            </p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[fntColor],marginBottom:"-2px"}}>Song On Youtube</h3>
                            <a href={link4} className="mb-4 bad-script-regular" aria-label="By" style={{display:"block",margin:"auto",textAlign:"center",color:[fntColor],fontSize:"10pt"}}>{pemilik4}</a>
                            <img src={bgImageBox1} className='w-100' style={{marginBottom:"20px"}}/>
                        </section>
                    </section>
                    {jenisWePernikahanParams === "aglaonema" || jenisWePernikahanParams === "agapanthus" || jenisWePernikahanParams === "amaryllis" || jenisWePernikahanParams === "alamanda"
                        ?<div>{kupuKupu.map((className, index) => (
                            <img key={index} src={pajangan} className={className} />
                        ))}</div>
                        :<div>{daun.map((className, index) => (
                            <img key={index} src={pajangan} className={className} />
                        ))}</div>
                    }
                </section>
            </section>
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN CONTOH
// EKSPOR
export default WePernikahanContoh