// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT MENU SIDE BAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE CV FORM
const MenuWeCvForm = () => { // KURUNG TUTUP RETURN
    // MEMBUAT VARIABEL WE CV FORM
    const { jenisWeCvParams } = useParams();
    const { panggilanParams } = useParams();
    const [panggilan, setPanggilan] = useState('')
    const [msg, setMsg] = useState("")
    const [displayLoader, setDisplayLoader] = useState("")
    const [foto, setFoto] = useState('')
    const [nama, setNama] = useState('')
    const [emailCv, setEmailCv] = useState('')
    const [noHandphone, setNoHandphone] = useState('')
    const [alamatRumah, setAlamatRumah] = useState('')
    const [kota, setKota] = useState('')
    const [kebangsaan, setKebangsaan] = useState('')
    const [kodePos, setKodePos] = useState('')
    const [tempatLahir, setTempatLahir] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [jenisKelamin, setJenisKelamin] = useState('')
    const [statusKawin, setStatusKawin] = useState('')
    const [agama, setAgama] = useState('')
    const [tinggiBadan, setTinggiBadan] = useState('')
    const [beratBadan, setBeratBadan] = useState('')
    const [linkedIn, setLinkedIn] = useState('')
    const [websitee, setWebsitee] = useState('')
    const [namaPerusahaan1, setNamaPerusahaan1] = useState('')
    const [posisiPekerjaan1, setPosisiPekerjaan1] = useState('')
    const [tanggalMulaiK1, setTanggalMulaiK1] = useState('')
    const [tanggalSelesaiK1, setTanggalSelesaiK1] = useState('')
    const [namaPerusahaan2, setNamaPerusahaan2] = useState('')
    const [posisiPekerjaan2, setPosisiPekerjaan2] = useState('')
    const [tanggalMulaiK2, setTanggalMulaiK2] = useState('')
    const [tanggalSelesaiK2, setTanggalSelesaiK2] = useState('')
    const [namaPerusahaan3, setNamaPerusahaan3] = useState('')
    const [posisiPekerjaan3, setPosisiPekerjaan3] = useState('')
    const [tanggalMulaiK3, setTanggalMulaiK3] = useState('')
    const [tanggalSelesaiK3, setTanggalSelesaiK3] = useState('')
    const [namaPerusahaan4, setNamaPerusahaan4] = useState('')
    const [posisiPekerjaan4, setPosisiPekerjaan4] = useState('')
    const [tanggalMulaiK4, setTanggalMulaiK4] = useState('')
    const [tanggalSelesaiK4, setTanggalSelesaiK4] = useState('')
    const [fresGraduate, setFresGraduate] = useState('')
    const [isChecked, setIsChecked] = useState(null)
    const [namaSekolah1, setNamaSekolah1] = useState('')
    const [programStudi1, setProgramStudi1] = useState('')
    const [tanggalMulaiP1, setTanggalMulaiP1] = useState('')
    const [tanggalSelesaiP1, setTanggalSelesaiP1] = useState('')
    const [namaSekolah2, setNamaSekolah2] = useState('')
    const [programStudi2, setProgramStudi2] = useState('')
    const [tanggalMulaiP2, setTanggalMulaiP2] = useState('')
    const [tanggalSelesaiP2, setTanggalSelesaiP2] = useState('')
    const [namaSekolah3, setNamaSekolah3] = useState('')
    const [programStudi3, setProgramStudi3] = useState('')
    const [tanggalMulaiP3, setTanggalMulaiP3] = useState('')
    const [tanggalSelesaiP3, setTanggalSelesaiP3] = useState('')
    const [namaSekolah4, setNamaSekolah4] = useState('')
    const [programStudi4, setProgramStudi4] = useState('')
    const [tanggalMulaiP4, setTanggalMulaiP4] = useState('')
    const [tanggalSelesaiP4, setTanggalSelesaiP4] = useState('')
    const [keahlian1, setKeahlian1] = useState('')
    const [keahlian2, setKeahlian2] = useState('')
    const [keahlian3, setKeahlian3] = useState('')
    const [keahlian4, setKeahlian4] = useState('')
    const [keahlian5, setKeahlian5] = useState('')
    const [keahlian6, setKeahlian6] = useState('')
    const [keahlian7, setKeahlian7] = useState('')
    const [keahlian8, setKeahlian8] = useState('')
    const [ceritaDiri, setCeritaDiri] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
            if(panggilanParams !== decoded.panggilan){
                try {
                    await axios.delete('http://localhost:5000/logoutBE')
                    navigate("/login/dashboard", { replace: true })
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-cv", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES PENAGMBILAN FILE FOTO DARI FORM
    function handleUploadChange(e){
        let uploaded = e.target.files[0]
        setFoto(uploaded)
    }
    // PROSES MENYIMPAN DATA WE CV KE DB
    const createWeCv = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("panggilan", panggilan)
        formData.append("foto", foto)
        formData.append("nama", nama)
        formData.append("emailCv", emailCv)
        formData.append("noHandphone", noHandphone)
        formData.append("alamatRumah", alamatRumah)
        formData.append("kota", kota)
        formData.append("kebangsaan", kebangsaan)
        formData.append("kodePos", kodePos)
        formData.append("tempatLahir", tempatLahir)
        formData.append("tanggalLahir", tanggalLahir)
        formData.append("jenisKelamin", jenisKelamin)
        formData.append("statusKawin", statusKawin)
        formData.append("agama", agama)
        formData.append("tinggiBadan", tinggiBadan)
        formData.append("beratBadan", beratBadan)
        formData.append("linkedIn", linkedIn)
        formData.append("websitee", websitee)
        formData.append("namaPerusahaan1", namaPerusahaan1)
        formData.append("posisiPekerjaan1", posisiPekerjaan1)
        formData.append("tanggalMulaiK1", tanggalMulaiK1)
        formData.append("tanggalSelesaiK1", tanggalSelesaiK1)
        formData.append("namaPerusahaan2", namaPerusahaan2)
        formData.append("posisiPekerjaan2", posisiPekerjaan2)
        formData.append("tanggalMulaiK2", tanggalMulaiK2)
        formData.append("tanggalSelesaiK2", tanggalSelesaiK2)
        formData.append("namaPerusahaan3", namaPerusahaan3)
        formData.append("posisiPekerjaan3", posisiPekerjaan3)
        formData.append("tanggalMulaiK3", tanggalMulaiK3)
        formData.append("tanggalSelesaiK3", tanggalSelesaiK3)
        formData.append("namaPerusahaan4", namaPerusahaan4)
        formData.append("posisiPekerjaan4", posisiPekerjaan4)
        formData.append("tanggalMulaiK4", tanggalMulaiK4)
        formData.append("tanggalSelesaiK4", tanggalSelesaiK4)
        formData.append("fresGraduate", fresGraduate)
        formData.append("namaSekolah1", namaSekolah1)
        formData.append("programStudi1", programStudi1)
        formData.append("tanggalMulaiP1", tanggalMulaiP1)
        formData.append("tanggalSelesaiP1", tanggalSelesaiP1)
        formData.append("namaSekolah2", namaSekolah2)
        formData.append("programStudi2", programStudi2)
        formData.append("tanggalMulaiP2", tanggalMulaiP2)
        formData.append("tanggalSelesaiP2", tanggalSelesaiP2)
        formData.append("namaSekolah3", namaSekolah3)
        formData.append("programStudi3", programStudi3)
        formData.append("tanggalMulaiP3", tanggalMulaiP3)
        formData.append("tanggalSelesaiP3", tanggalSelesaiP3)
        formData.append("namaSekolah4", namaSekolah4)
        formData.append("programStudi4", programStudi4)
        formData.append("tanggalMulaiP4", tanggalMulaiP4)
        formData.append("tanggalSelesaiP4", tanggalSelesaiP4)
        formData.append("keahlian1", keahlian1)
        formData.append("keahlian2", keahlian2)
        formData.append("keahlian3", keahlian3)
        formData.append("keahlian4", keahlian4)
        formData.append("keahlian5", keahlian5)
        formData.append("keahlian6", keahlian6)
        formData.append("keahlian7", keahlian7)
        formData.append("keahlian8", keahlian8)
        formData.append("ceritaDiri", ceritaDiri)
        formData.append("jenisCvG", jenisWeCvParams)
        try{
            setDisplayLoader("")
            await axios.patch("https://server13.webew.id/createWeCvBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            navigate(`/menu-we-cv-download/${panggilan}/${jenisWeCvParams}`, { replace: true })
        } catch(error){
            if(error.response){
                setDisplayLoader("none")
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES VALIDASI TULISAN FRESH GRADUATE
    const checkBoxHandler = () => {
        setIsChecked(!isChecked)
        isChecked ? setFresGraduate("") : setFresGraduate("Fresh Graduate")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="wrap-menu px-1 pb-5">
                            <div className="container-fluid pb-5">
                                    <div className="row justify-content-center pt-4">
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div className="col-12">
                                                <div className="box pt-3 pb-4 px-3">
                                                    <a href={`/menu-we-cv/${panggilan}`} className="btn btn-webew-product" style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                    <h3 className="title-webew-home" style={{marginTop:"10px"}}>Form We CV</h3>
                                                    <p className="text-webew-home">Silahkan isi form data We CV ini ya</p>
                                                    <a href={`/menu-we-cv-download/${panggilan}/${jenisWeCvParams}`} className="btn btn-webew-product mx-auto d-block">Lihat Punya Saya</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <div className="box p-4">
                                                <form onSubmit={createWeCv}>
                                                    <div className="accordion" id="accordionPanelsStayOpenExample" style={{marginTop:"-20px"}}>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">Data Pribadi</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="foto">Foto (JPEG/PNG)</label>
                                                                            <input type="file" id="foto" className="form-control form" accept="image/*" onChange={handleUploadChange} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="nama">Nama Lengkap</label>
                                                                            <input type="text" id="nama" className="form-control form" maxLength="37" value={nama} onChange={(e)=>setNama(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="emailCv">Alamat Email</label>
                                                                            <input type="email" id="emailCv" className="form-control form" maxLength="57" value={emailCv} onChange={(e)=>setEmailCv(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="noHandphone">Nomor Handphone</label>
                                                                            <input type="number" id="noHandphone" className="form-control form" maxLength="16" value={noHandphone} onChange={(e)=>setNoHandphone(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="alamatRumah">Alamat Rumah</label>
                                                                            <input type="text" id="alamatRumah" className="form-control form" maxLength="70" value={alamatRumah} onChange={(e)=>setAlamatRumah(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="kota">Kota</label>
                                                                            <input type="text" id="kota" className="form-control form" maxLength="27" value={kota} onChange={(e)=>setKota(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="kebangsaan">Provinsi</label>
                                                                            <input type="tex" id="kebangsaan" className="form-control form" maxLength="17" value={kebangsaan} onChange={(e)=>setKebangsaan(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="kodePos">Kode Pos</label>
                                                                            <input type="number" id="kodePos" className="form-control form" value={kodePos} onChange={(e)=>setKodePos(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tempatLahir">Tempat Lahir</label>
                                                                            <input type="text" id="tempatLahir" className="form-control form" maxLength="27" value={tempatLahir} onChange={(e)=>setTempatLahir(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalLahir">Tanggal Lahir</label>
                                                                            <input type="date" id="tanggalLahir" className="form-control form" value={tanggalLahir} onChange={(e)=>setTanggalLahir(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="jenisKelamin">Jenis Kelamin</label>
                                                                            <select className="form-select form" id="jenisKelamin" aria-label="jenisKelamin" value={jenisKelamin} onChange={(e)=>setJenisKelamin(e.target.value)} required>
                                                                                <option defaultValue="Laki-Laki">Pilih</option>
                                                                                <option defaultValue="Laki-Laki">Laki-Laki</option>
                                                                                <option defaultValue="Perempuan">Perempuan</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="">Status Kawin</label>
                                                                            <select className="form-select form" id="statusKawin" aria-label="statusKawin" value={statusKawin} onChange={(e)=>setStatusKawin(e.target.value)} required>
                                                                                <option defaultValue="Sudah Kawin">Pilih</option>
                                                                                <option defaultValue="Sudah Kawin">Sudah Kawin</option>
                                                                                <option defaultValue="Belum Kawin">Belum Kawin</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="">Agama</label>
                                                                            <select className="form-select form" id="agama" aria-label="agama" value={agama} onChange={(e)=>setAgama(e.target.value)} required>
                                                                                <option defaultValue="Islam">Pilih</option>
                                                                                <option defaultValue="Islam">Islam</option>
                                                                                <option defaultValue="Kristen">Kristen</option>
                                                                                <option defaultValue="Hindu">Hindu</option>
                                                                                <option defaultValue="Buddha">Buddha</option>
                                                                                <option defaultValue="Katolik">Katolik</option>
                                                                                <option defaultValue="Kong Hu Cu">Kong Hu Cu</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tinggiBadan">Tinggi Badan</label>
                                                                            <input type="number" id="tinggiBadan" className="form-control form" maxLength="270" value={tinggiBadan} onChange={(e)=>setTinggiBadan(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="beratBadan">Berat Badan</label>
                                                                            <input type="number" id="beratBadan" className="form-control form" maxLength="400" value={beratBadan} onChange={(e)=>setBeratBadan(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="linkedIn">linkedIn</label>
                                                                            <input type="text" id="linkedIn" className="form-control form" maxLength="57" value={linkedIn} onChange={(e)=>setLinkedIn(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="websitee">Website</label>
                                                                            <input type="text" id="websitee" className="form-control form" maxLength="57" value={websitee} onChange={(e)=>setWebsitee(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">Pengalaman Kerja</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <h6 className="mt-4 mb-0">Pengalaman Kerja 1</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPerusahaan1">Nama Perusahaan</label>
                                                                            <input type="text" id="namaPerusahaan1" className="form-control form" maxLength="40" value={namaPerusahaan1} onChange={(e)=>setNamaPerusahaan1(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="posisiPekerjaan1">Posisi Pekerjaan</label>
                                                                            <input type="text" id="posisiPekerjaan1" className="form-control form" maxLength="29" value={posisiPekerjaan1} onChange={(e)=>setPosisiPekerjaan1(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiK1">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiK1" className="form-control form" value={tanggalMulaiK1} onChange={(e)=>setTanggalMulaiK1(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiK1">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiK1" className="form-control form" value={tanggalSelesaiK1} onChange={(e)=>setTanggalSelesaiK1(e.target.value)}/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pengalaman Kerja 2</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPerusahaan2">Nama Perusahaan</label>
                                                                            <input type="text" id="namaPerusahaan2" className="form-control form" maxLength="40" value={namaPerusahaan2} onChange={(e)=>setNamaPerusahaan2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="posisiPekerjaan2">Posisi Pekerjaan</label>
                                                                            <input type="text" id="posisiPekerjaan2" className="form-control form" maxLength="29" value={posisiPekerjaan2} onChange={(e)=>setPosisiPekerjaan2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiK2">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiK2" className="form-control form" value={tanggalMulaiK2} onChange={(e)=>setTanggalMulaiK2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiK2">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiK2" className="form-control form" value={tanggalSelesaiK2} onChange={(e)=>setTanggalSelesaiK2(e.target.value)}/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pengalaman Kerja 3</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPerusahaan3">Nama Perusahaan</label>
                                                                            <input type="text" id="namaPerusahaan3" className="form-control form" maxLength="40" value={namaPerusahaan3} onChange={(e)=>setNamaPerusahaan3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="posisiPekerjaan3">Posisi Pekerjaan</label>
                                                                            <input type="text" id="posisiPekerjaan3" className="form-control form" maxLength="29" value={posisiPekerjaan3} onChange={(e)=>setPosisiPekerjaan3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiK3">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiK3" className="form-control form" value={tanggalMulaiK3} onChange={(e)=>setTanggalMulaiK3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiK3">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiK3" className="form-control form" value={tanggalSelesaiK3} onChange={(e)=>setTanggalSelesaiK3(e.target.value)}/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pengalaman Kerja 4</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaPerusahaan4">Nama Perusahaan</label>
                                                                            <input type="text" id="namaPerusahaan4" className="form-control form" maxLength="40" value={namaPerusahaan4} onChange={(e)=>setNamaPerusahaan4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="posisiPekerjaan4">Posisi Pekerjaan</label>
                                                                            <input type="text" id="posisiPekerjaan4" className="form-control form" maxLength="29" value={posisiPekerjaan4} onChange={(e)=>setPosisiPekerjaan4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiK4">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiK4" className="form-control form" value={tanggalMulaiK4} onChange={(e)=>setTanggalMulaiK4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiK4">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiK4" className="form-control form" value={tanggalSelesaiK4} onChange={(e)=>setTanggalSelesaiK4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="form-check mt-4 mx-3 mb-0">
                                                                            <input className="form-check-input form" type="checkbox" id="isChecked" checked={isChecked} onChange={checkBoxHandler}/>
                                                                            <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="fresGraduate">
                                                                                <h6>Atau Fresh Graduate</h6>
                                                                            </label>
                                                                            <input type="text" id='fresGraduate' className='form-disable' value={fresGraduate} readOnly/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">Pendidikan</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                                        <h6 className="mt-4 mb-0">Pendidikan 1</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaSekolah1">Nama Sekolah / Kuliah</label>
                                                                            <input type="text" id="namaSekolah1" className="form-control form" maxLength="40" value={namaSekolah1} onChange={(e)=>setNamaSekolah1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="programStudi1">Program Studi / Jurusan</label>
                                                                            <input type="text" id="programStudi1" className="form-control form" maxLength="29" value={programStudi1} onChange={(e)=>setProgramStudi1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiP1">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiP1" className="form-control form" value={tanggalMulaiP1} onChange={(e)=>setTanggalMulaiP1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiP1">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiP1" className="form-control form" value={tanggalSelesaiP1} onChange={(e)=>setTanggalSelesaiP1(e.target.value)} required/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pendidikan 2</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaSekolah2">Nama Sekolah / Kuliah</label>
                                                                            <input type="text" id="namaSekolah2" className="form-control form" maxLength="40" value={namaSekolah2} onChange={(e)=>setNamaSekolah2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="programStudi2">Program Studi / Jurusan</label>
                                                                            <input type="text" id="programStudi2" className="form-control form" maxLength="29" value={programStudi2} onChange={(e)=>setProgramStudi2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiP2">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiP2" className="form-control form" value={tanggalMulaiP2} onChange={(e)=>setTanggalMulaiP2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiP2">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiP2" className="form-control form" value={tanggalSelesaiP2} onChange={(e)=>setTanggalSelesaiP2(e.target.value)}/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pendidikan 3</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaSekolah3">Nama Sekolah / Kuliah</label>
                                                                            <input type="text" id="namaSekolah3" className="form-control form" maxLength="40" value={namaSekolah3} onChange={(e)=>setNamaSekolah3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="programStudi3">Program Studi / Jurusan</label>
                                                                            <input type="text" id="programStudi3" className="form-control form" maxLength="29" value={programStudi3} onChange={(e)=>setProgramStudi3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiP3">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiP3" className="form-control form" value={tanggalMulaiP3} onChange={(e)=>setTanggalMulaiP3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiP3">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiP3" className="form-control form" value={tanggalSelesaiP3} onChange={(e)=>setTanggalSelesaiP3(e.target.value)}/>
                                                                        </div>
                                                                        <h6 className="mt-4 mb-0">Pendidikan 4</h6>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="namaSekolah4">Nama Sekolah / Kuliah</label>
                                                                            <input type="text" id="namaSekolah4" className="form-control form" maxLength="40" value={namaSekolah4} onChange={(e)=>setNamaSekolah4(e.target.value)}/>
                                                                        </div>
                                                                        <input type="text" id="jenisCvG" className="form-disable" value={jenisWeCvParams} readOnly/>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="programStudi4">Program Studi / Jurusan</label>
                                                                            <input type="text" id="programStudi4" className="form-control form" maxLength="29" value={programStudi4} onChange={(e)=>setProgramStudi4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalMulaiP4">Tanggal Mulai</label>
                                                                            <input type="date" id="tanggalMulaiP4" className="form-control form" value={tanggalMulaiP4} onChange={(e)=>setTanggalMulaiP4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="tanggalSelesaiP4">Tanggal Selesai</label>
                                                                            <input type="date" id="tanggalSelesaiP4" className="form-control form" value={tanggalSelesaiP4} onChange={(e)=>setTanggalSelesaiP4(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"aria-controls="panelsStayOpen-collapseFour">Keahlian Kalian</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian1">Keahlian 1</label>
                                                                            <input type="text" id="keahlian1" className="form-control form" maxLength="45" value={keahlian1} onChange={(e)=>setKeahlian1(e.target.value)} required/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian2">Keahlian 2</label>
                                                                            <input type="text" id="keahlian2" className="form-control form" maxLength="45" value={keahlian2} onChange={(e)=>setKeahlian2(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian3">Keahlian 3</label>
                                                                            <input type="text" id="keahlian3" className="form-control form" maxLength="45" value={keahlian3} onChange={(e)=>setKeahlian3(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian4">Keahlian 4</label>
                                                                            <input type="text" id="keahlian4" className="form-control form" maxLength="45" value={keahlian4} onChange={(e)=>setKeahlian4(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian5">Keahlian 5</label>
                                                                            <input type="text" id="keahlian5" className="form-control form" maxLength="45" value={keahlian5} onChange={(e)=>setKeahlian5(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian6">Keahlian 6</label>
                                                                            <input type="text" id="keahlian6" className="form-control form" maxLength="45" value={keahlian6} onChange={(e)=>setKeahlian6(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian7">Keahlian 7</label>
                                                                            <input type="text" id="keahlian7" className="form-control form" maxLength="45" value={keahlian7} onChange={(e)=>setKeahlian7(e.target.value)}/>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="keahlian8">Keahlian 8</label>
                                                                            <input type="text" id="keahlian8" className="form-control form" maxLength="45" value={keahlian8} onChange={(e)=>setKeahlian8(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"aria-controls="panelsStayOpen-collapseFive">Ceritakan Diri Kalian</button>
                                                            </h2>
                                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                                                <div className="accordion-body">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                                                            <label style={{fontSize:"10pt"}} htmlFor="ceritaDiri">Ceritakan</label>
                                                                            <input type="text" id="ceritaDiri" className="form-control form" maxLength="245" value={ceritaDiri} onChange={(e)=>setCeritaDiri(e.target.value)} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 style={{textAlign:"center",marginTop:"20px",marginBottom:"-20px",color:"red"}}>{msg}</h6>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <input type="submit" className="btn btn-webew-product mt-4 py-4" value="Proses Bikin CV"/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                            </div>    
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE CV FORM
// EKSPOR
export default MenuWeCvForm