// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT JWT DECODE
import jwt_decode from 'jwt-decode'
// IMPORT USE PARAMS, USE NAVIGATE
import { useParams, useNavigate } from 'react-router-dom'
// IMPORT FORMAT RUPIAH
import { FormatRupiah } from "@arismun/format-rupiah"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBarAdmin.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbarAdmin.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBotAdmin.jsx'
// MENU WE DASHBOARD
const MenuAdminWeDashboard = () => { // KURUNG BUKA MENU WE DASHBOARD
    // MEMBUAT VARIABEL WE DASHBOARD
    const { panggilanParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState('')
    const [urlFoto, setUrlFoto] = useState('')
    const [foto, setFoto] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [panggilan, setPanggilan] = useState('')
    const [dataTransaksi, setDataTransaksi] = useState([])
    const [saran, setSaran] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL MESSAGE
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWeAdmin()
        SelectDataTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setName(decoded.name)
            setEmail(decoded.email)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader('none')
        } catch (error) {
            if(error.response){
                navigate('/lowemin', { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokMinBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setName(decoded.name)
            setEmail(decoded.email)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENGAMBIL FILE FOTO
    function handleUploadChange(e){
        let uploaded = e.target.files[0]
        setFoto(uploaded)
    }
    // PROSES MENAMPILKAN DATA TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const SelectDataTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectAllTransaksiBE`)
            setDataTransaksi(response.data)
        }catch(error){
            console.log(error)
        }
    }
    // PROSES MENAMPILKAN WE ADMIN BERDASARKAN PANGGILAN BY PARAMS
    const selectWeAdmin = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeAdminBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENGUBAH ADMIN
    const updateWeAdmin = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('foto', foto)
        formData.append('name', name)
        formData.append('panggilan', panggilan)
        try{
            setDisplayLoader('')
            await axios.patch('https://server13.webew.id/updateWeAdminBE', formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                setDisplayLoader('none')
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENJUMLAHKAN TOTAL PENDAPATAN
    const totalAmount = dataTransaksi.reduce((acc, user) => {
        // Pastikan nilai total adalah angka, jika tidak ada atau tidak valid, gunakan 0
        const totalValue = Number(user.total) || 0;
        return acc + totalValue;
      }, 0);
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id='wrapper'>
            {/* LOADER */}
                <div className='loader-page-bg' style={{display:displayLoader}}>
                    <div className='loader-page'></div>
                </div>
            {/* TUTUP LOADER */}
            {/* SIDEBAR*/}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id='content-wrapper'>
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>Dashboard Admin</h4>
                                        </div>
                                    </div>
                                    <div className='row mb-5 pb-5'>
                                        <div className='col-12 col-lg-6 mt-2'>
                                            <div className='row'>
                                                <div className='col-12 mb-4'>
                                                    <div className='box p-4' style={{width: '100%',minHeight:'275px'}}>
                                                        <form onSubmit={updateWeAdmin}>
                                                            <h6 style={{textAlign:'center',marginTop:'10px',marginBottom:'10px',color:'red'}}>{msg}</h6>
                                                            <div className='row'>
                                                                <div className='col-12 col-md-6 mb-3'>
                                                                    { urlFoto === null
                                                                        ? <img src='https://webew.id/img/default.jpg' className='mx-auto d-block' alt='foto' style={{minWidth:'130px',maxWidth:'130px',borderRadius:'10px'}}/>
                                                                        : <img src={urlFoto} className='mx-auto d-block' alt='foto' style={{minWidth:'130px',maxWidth:'130px',borderRadius:'10px'}}/>
                                                                    }
                                                                    <h6 className='text-center mt-2' style={{fontWeight:'600',fontSize:"10pt",marginBottom:"-4px"}}>{email}</h6>
                                                                </div>
                                                                <div className='col-12 col-md-6'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <label htmlFor='foto' className='mx-2' style={{fontSize:'10pt'}}>Foto Profil (Max 1MB)</label>
                                                                            <input type='file' id='foto' className='form-control form' accept='image/*' title='Masukan foto max 1MB' onChange={handleUploadChange} style={{fontSize:'11pt'}}required/>
                                                                        </div>
                                                                        <div className='col-12 mt-3'>
                                                                            <label htmlFor='name' className='mx-2' style={{fontSize:'10pt'}}>Ubah Nama</label>
                                                                            <input type='text' className='form-disable form mb-3' value={panggilan} readOnly/>
                                                                            <input type='text' className='form-control form mb-3' value={name} onChange={(e)=> setName(e.target.value)} style={{fontSize:'11pt'}} required/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <input type='submit' className='btn btn-webew-login my-2' value='Ubah'/>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='box p-3' style={{width: '100%',minHeight:'108px'}}>
                                                        <h4 className='text-center mt-4' style={{fontWeight:'700'}}>Total Penghasilan: <FormatRupiah value={totalAmount}/></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6 mt-2'>
                                            <div className='box py-4 px-0' style={{width: '100%',minHeight:'417px',maxHeight:'417px',overflow:"auto"}}>
                                                <h6 className='text-center mb-3' style={{fontWeight:'700'}}>Riwayat Transaksi</h6>
                                                <table style={{width:"800px",overflow:"auto"}}>
                                                    <thead>
                                                        <tr style={{borderBottom:'2px solid #f5f5f5',borderTop:'2px solid #f5f5f5'}}>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No.</td>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>No. Order</td>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Nama</td>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Harga</td>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Created At</td>
                                                            <td className='py-2 fw-bold' style={{color:'rgb(108, 108, 108)'}}>Updated At</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataTransaksi.map((user,index)=>(
                                                            <tr key={dataTransaksi.id} style={{borderBottom:'2px solid #f5f5f5'}}>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{index + 1}.</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.order_id}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.nama}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><FormatRupiah value={user.total}/></td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.createdAt}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.updatedAt}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAP */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE DASHBOARD
// EKSPOR
export default MenuAdminWeDashboard