// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// WE PERNIKAHAN HASIL
const WePernikahanHasil = () => { // BUKA KURUNG WE PERNIKAHAN HASIL
    // MEMBUAT VARIABEL WE PERNIKAHAN HASIL
    const [pajangan, setPajangan] = useState('')
    const [displayLoader, setDisplayLoader] = useState("")
    const [msg, setMsg] = useState('')
    const [msgUcapan, setMsgUcapan] = useState('')
    const [nama, setNama] = useState('')
    const [status, setStatus] = useState('')
    const [ucapan, setUcapan] = useState('')

    const [btnColorCD, setBtnColorCD] = useState('')
    const [btnColor, setBtnColor] = useState('')
    const [fntColor, setFntColor] = useState('')
    const [borderBtn, setBorderBtn] = useState('')
    const [borderBoxLeft, setBorderBoxLeft] = useState('none')
    const [borderBoxRight, setBorderBoxRight] = useState('none')
    const [bgCover, setBgCover] = useState('')
    const [bgImageCover, setBgImageCover] = useState('')
    const [bgColor, setBgColor] = useState('')
    const [bgImageBox1, setBgImageBox1] = useState('')

    const [imageGallery1, setImageGallery1] = useState('')
    const [imageGallery2, setImageGallery2] = useState('')
    const [imageGallery3, setImageGallery3] = useState('')
    const [imageGallery4, setImageGallery4] = useState('')
    const [imageGallery5, setImageGallery5] = useState('')
    const [imageGallery6, setImageGallery6] = useState('')

    const [imagePengantin1, setImagePengantin1] = useState('')
    const [imagePengantin2, setImagePengantin2] = useState('')
    const [namaPanggilanPengantin1, setNamaPanggilanPengantin1] = useState('')
    const [namaPanggilanPengantin2, setNamaPanggilanPengantin2] = useState('')
    const [namaPengantin1, setNamaPengantin1] = useState('')
    const [namaPengantin2, setNamaPengantin2] = useState('')
    const [namaSosmedPengantin1, setNamaSosmedPengantin1] = useState('')
    const [namaSosmedPengantin2, setNamaSosmedPengantin2] = useState('')
    const [kelaminPengantin1, setKelaminPengantin1] = useState('')
    const [kelaminPengantin2, setKelaminPengantin2] = useState('')
    const [urutanPengantin1, setUrutanPengantin1] = useState('')
    const [urutanPengantin2, setUrutanPengantin2] = useState('')
    const [namaBapakPengantin1, setNamaBapakPengantin1] = useState('')
    const [namaBapakPengantin2, setNamaBapakPengantin2] = useState('')
    const [namaIbuPengantin1, setNamaIbuPengantin1] = useState('')
    const [namaIbuPengantin2, setNamaIbuPengantin2] = useState('')

    const [bgColorBox2, setBgColorBox2] = useState('')

    const [hariAkad, setHariAkad] = useState('')
    const [tanggalAkad, setTanggalAkad] = useState('')
    const [jamMulaiAkad, setJamMulaiAkad] = useState('')
    const [jamAkhirAkad, setJamAkhirAkad] = useState('')
    const [hariResepsi, setHariResepsi] = useState('')
    const [tanggalResepsi, setTanggalResepsi] = useState('')
    const [jamMulaiResepsi, setJamMulaiResepsi] = useState('')
    const [jamAkhirResepsi, setJamAkhirResepsi] = useState('')
    const [zonaWaktuAkad, setZonaWaktuAkad] = useState('')
    const [zonaWaktuResepsi, setZonaWaktuResepsi] = useState('')
    const [kalenderAkad, setKalenderAkad] = useState('')
    const [kalenderResepsi, setKalenderResepsi] = useState('')

    const [bgImageBox2, setBgImageBox2] = useState('')

    const [alamatAkad, setAlamatAkad] = useState('')
    const [linkMapsAlamatAkad, setLinkMapsAlamatAkad] = useState('')
    const [alamatResepsi, setAlamatResepsi] = useState('')
    const [linkMapsAlamatResepsi, setLinkMapsAlamatResepsi] = useState('')

    const [ayat, setAyat] = useState('')
    const [surah, setSurah] = useState('')    

    const [namaBank1, setNamaBank1] = useState('')
    const [namaPemilikBank1, setNamaPemilikBank1] = useState('')
    const [norekBank1, setNorekBank1] = useState('')
    const [namaBank2, setNamaBank2] = useState('')
    const [namaPemilikBank2, setNamaPemilikBank2] = useState('')
    const [norekBank2, setNorekBank2] = useState('')

    const [musik, setMusik] = useState('')

    const [rotate, setRotate] = useState('')

    const [pemilik1, setPemilik1] = useState('')
    const [link1, setLink1] = useState('')
    const [pemilik2, setPemilik2] = useState('')
    const [link2, setLink2] = useState('')
    const [pemilik3, setPemilik3] = useState('')
    const [link3, setLink3] = useState('')
    const [pemilik4, setPemilik4] = useState('')
    const [link4, setLink4] = useState('')

    const [bukaUndangan, setBukaUndangan] = useState('none')
    const [tutupButton, setTutupButton] = useState('')
    const [isPlaying, setIsPlaying] = useState(false);

    const [jenisWePernikahan, setJenisWePernikahan] = useState('')
    const [panggilan, setPanggilan] = useState('')
    const [salinLinkk, setSalinLinkk] = useState('')
    
    const [dataUcapan, setDataUcapan] = useState([])
    const { salinLink } = useParams();
    const { namaTamu } = useParams();
    
    const audioRef = useRef(null);
    const inputNoRek1 = useRef(null);
    const inputNoRek2 = useRef(null);

    const targetDate = `${tanggalAkad}T${jamMulaiAkad.replace(/^(\d{2}).*$/, '$1')}:00:00`;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());    

    const elementsRef = useRef([]);

    // EVENT COUNTDOWN
    function calculateTimeLeft() {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return null;
    }
    // USEEFFECT
    useEffect(() => {
        selectWePernikahanHasil()
        selectUcapanWePernikahanLive()
        // Jenis we pernikahan
        if(jenisWePernikahan === "agapanthus"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_agapanthus.png")
            setDisplayLoader("none")
            setBtnColorCD("#0081b0")
            setBtnColor("#f3fbff")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #0081b0")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png")
            // Box 2
            setBgColorBox2("#4fd0ff15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "aglaonema"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_aglaonema.png")
            setDisplayLoader("none")
            setBtnColorCD("green")
            setBtnColor("#f4fff3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid green")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            setBgColor("#fafff82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png")
            // Box 2
            setBgColorBox2("#5eff4f15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph")
            setPemilik2("pikisuperstar")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "alamanda"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_alamanda.png")
            setDisplayLoader("none")
            setBtnColorCD("#b05500")
            setBtnColor("#fffaf3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b05500")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fffcf82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "amaryllis"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/rb_162204_amaryllis.png")
            setDisplayLoader("none")
            setBtnColorCD("#b00000")
            setBtnColor("#fff3f3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b00000")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fff8f82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png")
            // Box 2
            setBgColorBox2("#ff4f4f15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais")
            setPemilik1("freepik")
            setLink2("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "adorable"){
            setDisplayLoader("none")
            setBtnColorCD("#00b07e")
            setBtnColor("#f3fff8")
            setFntColor("rgb(0, 58, 41)")
            setBorderBtn("3px solid #00b07e")
            setBgCover("linear-gradient(to bottom,rgb(221, 255, 237),rgb(221, 255, 237)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#f8fffd2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#4fffc115")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "cute"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(176, 0, 135)")
            setBtnColor("#fff3fe")
            setFntColor("rgb(61, 0, 47)")
            setBorderBtn("3px solid rgb(176, 0, 135)")
            setBgCover("linear-gradient(to bottom, #f1c9fe,rgb(245, 214, 255)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fff8fc2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#ff4fb915")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "beauty"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(176, 59, 0)")
            setBtnColor("#fff7f3")
            setFntColor("rgb(68, 26, 0)")
            setBorderBtn("3px solid rgb(176, 59, 0)")
            setBgCover("linear-gradient(to bottom, #fed5c9,rgb(255, 225, 215)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fffbf82f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "cool"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(0, 147, 176)")
            setBtnColor("#f3fcff")
            setFntColor("rgb(0, 46, 55)")
            setBorderBtn("3px solid rgb(0, 147, 176)")
            setBgCover("linear-gradient(to bottom, rgb(212, 240, 255),rgb(212, 240, 255)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#4fdcff15")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "blegold"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(176, 135, 0)")
            setBtnColor("#392f00")
            setFntColor("rgb(255, 220, 80)")
            setBorderBtn("3px solid rgb(176, 135, 0)")
            setBorderBoxLeft("3px solid rgb(255, 204, 0)")
            setBorderBoxRight("3px solid rgb(255, 204, 0)")
            setBgCover("linear-gradient(to bottom,rgb(70, 61, 0),rgb(0, 0, 0)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fffef82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png")
            // Box 2
            setBgColorBox2("#ffed4f15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "blepink"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(255, 44, 209)")
            setBtnColor("rgb(67, 0, 62)")
            setFntColor("rgb(255, 169, 249)")
            setBorderBtn("3px solid rgb(255, 190, 237)")
            setBorderBoxLeft("3px solid rgb(255, 190, 237)")
            setBorderBoxRight("3px solid rgb(255, 190, 237)")
            setBgCover("linear-gradient(to bottom,rgb(74, 0, 77),rgb(0, 0, 0)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#fff8fd2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png")
            // Box 2
            setBgColorBox2("#ff4fd015")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "bletosca"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(0, 130, 133)")
            setBtnColor("#004748")
            setFntColor("rgb(185, 254, 255)")
            setBorderBtn("3px solid rgb(0, 251, 255)")
            setBorderBoxLeft("3px solid rgb(0, 247, 255)")
            setBorderBoxRight("3px solid rgb(0, 247, 255)")
            setBgCover("linear-gradient(to bottom,rgb(0, 62, 64),rgb(0, 0, 0)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png")
            // Box 2
            setBgColorBox2("#4ffcff15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "blewhite"){
            setDisplayLoader("none")
            setBtnColorCD("rgb(0, 0, 0)")
            setBtnColor("rgb(73, 73, 73)")
            setFntColor("rgb(255, 255, 255)")
            setBorderBtn("3px solid rgb(255, 255, 255)")
            setBorderBoxLeft("3px solid rgb(255, 255, 255)")
            setBorderBoxRight("3px solid rgb(255, 255, 255)")
            setBgCover("linear-gradient(to bottom,rgb(76, 76, 76),rgb(0, 0, 0)")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#ffffff2f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png")
            // Box 2
            setBgColorBox2("#ffffff15")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "bnwI"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setDisplayLoader("none")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "bnwII"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setDisplayLoader("none")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "bnwIII"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setDisplayLoader("none")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahan === "bnwIV"){
            setPajangan("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/9107249_bnw.png")
            setDisplayLoader("none")
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Undangan
            // Cover Top
            
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }
        // Countdown
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            if (newTimeLeft) {
                setTimeLeft(newTimeLeft);
            } else {
                clearInterval(timer);
            }
        }, 1000);
        // Membersihkan interval saat komponen dilepas
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDate]);
    // ANIMASI SCROLL
    useEffect(() => {
        // animasi scroll
        if (!Array.isArray(elementsRef.current[0])) {
            elementsRef.current[0] = []; // Pastikan current[0] diinisialisasi sebagai array
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const element = entry.target;
                    animateElement(element); // Panggil fungsi untuk menganimasikan elemen
                }
                });
            },
            {
                threshold: 0, // Elemen terlihat 0% sebelum animasi dijalankan
                rootMargin: "0px 0px 0px 0px",
            }
        );
        elementsRef.current[0].forEach((el) => observer.observe(el)); // Iterasi semua elemen di current[0]
        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const animateElement = (element) => {
        let start = null;

        const duration = 1200; // Durasi animasi dalam milidetik
        const initialScale = 0.5; // Ukuran awal (kecil)
        const targetScale = 1; // Ukuran akhir (normal)
        const initialOpacity = 0; // Transparan awal
        const targetOpacity = 1; // Transparan akhir
    
        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = Math.min((timestamp - start) / duration, 1); // Hitung progres animasi (0 ke 1)
    
          // Lerp (Linear Interpolation) untuk scale dan opacity
          const currentScale = initialScale + (targetScale - initialScale) * progress;
          const currentOpacity = initialOpacity + (targetOpacity - initialOpacity) * progress;
    
          // Atur gaya elemen
          element.style.transform = `scale(${currentScale})`;
          element.style.opacity = currentOpacity;
    
          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };
    
        requestAnimationFrame(step);
    };
    // PROSES MENAMPILKAN DATA WE PERNIKAHAN BERDASARKAN SALIN LINK BY PARAMS
    const selectWePernikahanHasil = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWePernikahanHasilBE/${salinLink}`)
            
            setBgImageCover("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlBgImageCover)
            setImagePengantin1("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImagePengantin1)
            setImagePengantin2("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImagePengantin2)
            setImageGallery1("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery1)
            setImageGallery2("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery2)
            setImageGallery3("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery3)
            setImageGallery4("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery4)
            setImageGallery5("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery5)
            setImageGallery6("https://server13.webew.id/imagesUndanganDigitalPernikahan/"+response.data.urlImageGallery6)

            setNamaPanggilanPengantin1(response.data.namaPanggilanPengantin1)
            setNamaPanggilanPengantin2(response.data.namaPanggilanPengantin2)
            setNamaPengantin1(response.data.namaPengantin1)
            setNamaSosmedPengantin1(response.data.namaSosmedPengantin1)
            setNamaPengantin2(response.data.namaPengantin2)
            setNamaSosmedPengantin2(response.data.namaSosmedPengantin2)
            setKelaminPengantin1(response.data.kelaminPengantin1)
            setKelaminPengantin2(response.data.kelaminPengantin2)
            setUrutanPengantin1(response.data.urutanPengantin1)
            setUrutanPengantin2(response.data.urutanPengantin2)
            setNamaBapakPengantin1(response.data.namaBapakPengantin1)
            setNamaBapakPengantin2(response.data.namaBapakPengantin2)
            setNamaIbuPengantin1(response.data.namaIbuPengantin1)
            setNamaIbuPengantin2(response.data.namaIbuPengantin2)

            setHariAkad(response.data.hariAkad)
            setTanggalAkad(response.data.tanggalAkad)
            setJamMulaiAkad(response.data.jamMulaiAkad)
            setJamAkhirAkad(response.data.jamAkhirAkad)
            setHariResepsi(response.data.hariResepsi)
            setTanggalResepsi(response.data.tanggalResepsi)
            setJamMulaiResepsi(response.data.jamMulaiResepsi)
            setJamAkhirResepsi(response.data.jamAkhirResepsi)
            setZonaWaktuAkad(response.data.zonaWaktuAkad)
            setZonaWaktuResepsi(response.data.zonaWaktuResepsi)

            setAlamatAkad(response.data.alamatAkad)
            setLinkMapsAlamatAkad(response.data.linkMapsAlamatAkad)
            setAlamatResepsi(response.data.alamatResepsi)
            setLinkMapsAlamatResepsi(response.data.linkMapsAlamatResepsi)

            setAyat(response.data.ayat)
            setSurah(response.data.surah)

            setNamaBank1(response.data.namaBank1)
            setNamaPemilikBank1(response.data.namaPemilikBank1)
            setNorekBank1(response.data.norekBank1)
            setNamaBank2(response.data.namaBank2)
            setNamaPemilikBank2(response.data.namaPemilikBank2)
            setNorekBank2(response.data.norekBank2)

            setMusik(response.data.musik)

            setJenisWePernikahan(response.data.jenisUndanganDigitalPernikahan)
            setPanggilan(response.data.panggilan)
            setSalinLinkk(response.data.salinLink)

            const tanggalAwalAkad = response.data.tanggalAkad.replace(/[^\w\s]/gi, '') + "T" + response.data.jamMulaiAkad.replace(/[^\w\s]/gi, '') + "00"
            const tanggalAkhirAkad = response.data.tanggalAkad.replace(/[^\w\s]/gi, '') + "T" + response.data.jamAkhirAkad.replace(/[^\w\s]/gi, '') + "00"
            setKalenderAkad(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${response.data.namaPanggilanPengantin1} dan ${response.data.namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Akad) kami ${response.data.namaPanggilanPengantin1} dan ${response.data.namaPanggilanPengantin2} pada hari ${response.data.hariAkad}, ${response.data.tanggalAkad}, dari jam ${response.data.jamMulaiAkad} s/d ${response.data.jamAkhirAkad}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalAkad}/${tanggalAkhirAkad}&location=Indonesia`)
            const tanggalAwalResepsi = response.data.tanggalResepsi.replace(/[^\w\s]/gi, '') + "T" + response.data.jamMulaiResepsi.replace(/[^\w\s]/gi, '') + "00"
            const tanggalAkhirResepsi = response.data.tanggalResepsi.replace(/[^\w\s]/gi, '') + "T" + response.data.jamAkhirResepsi.replace(/[^\w\s]/gi, '') + "00"
            setKalenderResepsi(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${response.data.namaPanggilanPengantin1} dan ${response.data.namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Resepsi) kami ${response.data.namaPanggilanPengantin1} dan ${response.data.namaPanggilanPengantin2} pada hari ${response.data.hariResepsi}, ${response.data.tanggalResepsi}, dari jam ${response.data.jamMulaiResepsi} s/d ${response.data.jamAkhirResepsi}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalResepsi}/${tanggalAkhirResepsi}&location=Indonesia`)
        
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN UCAPAN WE PERNIKAHAN LIVE BERDASARKAN SALIN LINK BY PARAMS
    const selectUcapanWePernikahanLive = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectUcapanWePernikahanLiveBE/${salinLink}`)
            setDataUcapan(response.data)
        }catch(error){
            if(error.response){
                setMsgUcapan(error.response.data.msg)
            }
        }
    }
    // PROSES MENYIMPAN UCAPAN WE PERNIKAHAN
    const createUcapanWePernikahan = async(e)=>{
        e.preventDefault()
        try {
            await axios.post('https://server13.webew.id/createUcapanWePernikahanBE',{
                nama: nama,
                status: status,
                ucapan: ucapan,
                salinLink: salinLinkk,
                panggilan: panggilan
            })
            setMsgUcapan("Ucapan Berhasil Dikirim")
            // window.location.reload()
            setNama("")
            setStatus("")
            setUcapan("")
            selectUcapanWePernikahanLive()
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        const element = document.documentElement; // Elemen yang ingin dijadikan fullscreen (seluruh dokumen)
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen(); // Untuk Firefox
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(); // Untuk Chrome, Safari, dan Opera
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen(); // Untuk IE/Edge
        }
        setBukaUndangan("")
        setTutupButton("none")
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PLAY MUSSIC
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    // BUTTON PAUSE MUSIC
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    // BUTTON PLAY N PAUSE MUSIC
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    // PROSES SALIN REKENING 1
    function salinRek1(e) {
        inputNoRek1.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES SALIN REKENING 2
    function salinRek2(e) {
        inputNoRek2.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // Fungsi untuk memformat tanggal ke gaya Indonesia
    function formatDateToIndonesian(date) {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
          ];
      
          if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return "13 Mei 2022";
          }
          const [year, month, day] = date.split("-");
          const monthName = months[parseInt(month, 10) - 1];
          return `${parseInt(day, 10)} ${monthName} ${year}`;
    }
    // CLASSNAME PAJANGAN 
    const kupuKupu = [
        "moving-image-1",
        "moving-image-2",
        "moving-image-3",
        "moving-image-4",
        "moving-image-5",
    ];
    const daun = [
        "moving-image-6",
        "moving-image-7",
        "moving-image-8",
        "moving-image-9",
        "moving-image-10",
    ];
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div className="background-home">
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,background: "linear-gradient(to top, rgb(0, 12, 36) 20%, rgb(0, 39, 80) 100%"}}>
                <div className="loader-page-2" style={{border: `10px solid white`,borderTop:`10px solid blue`}}></div>
                <p style={{fontSize:"20pt",color:"white",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            <section style={{display:bgImageCover ? "":"none"}}>
                {/* COVER UNDANGAN */}
                <div style={{position:"fixed",minHeight:"100vh",width:"100%",background: `linear-gradient(to top, ${btnColorCD} -100%, ${bgColorBox2}) 100%`,zIndex:"1",display:tutupButton,overflow:"auto"}}>
                    <div className="container-fluid">
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh",width:"100%",zIndex:"2"}}>
                            <div className="px-4 py-4" style={{backgroundImage:[bgCover],height:"auto",width:"auto",borderRadius:"20px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                <img src={bgImageCover} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:`0px 10px 25px -14px ${btnColorCD}`}} alt="pengantin" />
                                <h5 className='fw-bold bad-script-regular text-center' style={{marginTop:"30px",color:[fntColor]}}>Kepada Yth. Bpk/Ibu/Saudara/i</h5>
                                <p className='fw-normal courgette-regular m-0 text-center' style={{color:[fntColor],fontSize:"40pt"}}>{namaTamu}</p>
                                <button onClick={btnBukaUndangan} className="form form-control fw-bold mx-auto d-block mt-2 px-4 bad-script-regular" style={{width:"auto",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],backgroundColor:[btnColor],display:tutupButton,border:[borderBtn],fontSize:"13pt"}}><i className="bi bi-envelope-paper-heart"></i> Buka Undangan</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* AUDIO */}
                <div className="fixed-bottom" style={{display:bukaUndangan}}>
                    <audio ref={audioRef} src={`/song/${musik}.mp3`} autoPlay loop preload='none'/>
                    <button onClick={handlePlayPause} style={{width:"100%",height:"auto",border:"none",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderTop:[borderBtn]}}>
                        {isPlaying ? <p style={{color:[fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-pause-circle" style={{fontSize:"18px"}}></i> {musik} </p>:<p style={{color:[fntColor],fontWeight:"600",margin:"0",padding:"0",fontSize:"10pt"}}><i className="bi bi-play-circle" style={{fontSize:"18px"}}></i> {musik}</p>}                                           
                    </button>
                </div>
                {/* COVER */}
                <section style={{overflow:"auto",height:"100vh"}}>
                    <section style={{backgroundImage:"url('"+`${bgImageCover}`+"')",width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                        <div style={{background: `linear-gradient(to top, ${btnColorCD} -100%, ${bgColor} 100%)`,width:"100%",height:"100vh",display:[bukaUndangan]}}>
                            <div className="marginTopWePernikahan" style={{width:"100%",marginBottom:"18px",textAlign:"center",textShadow:`0 0 20px ${btnColorCD}`,display:[bukaUndangan]}}>
                                <p className='fw-bold courgette-regular' style={{color:"white",fontSize:"40pt"}}>{namaPanggilanPengantin1} & {namaPanggilanPengantin2}</p>
                                <h2 className='fw-bold bad-script-regular' style={{color:"white",fontVariant:"small-caps", marginTop:"-25px"}}>{hariAkad}, {formatDateToIndonesian(tanggalAkad)}</h2>
                            </div>
                            {timeLeft ? (
                                <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                    <table style={{ textAlign: "center", width: "100%" }}>
                                        <tbody>
                                            <tr style={{ textAlign: "center", width: "100%" }}>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.days}</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.hours}</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.minutes}</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>{timeLeft.seconds}</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className='px-1' style={{width: "100%", marginBottom:"18px" }}>
                                    <table style={{ textAlign: "center", width: "100%" }}>
                                        <tbody>
                                            <tr style={{ textAlign: "center", width: "100%" }}>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                </td>
                                                <td style={{ width: "2%" }}></td>
                                                <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,borderRadius: "30px",boxShadow: `inset 0 2px 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "23pt" }}>0</div>
                                                    <div className="fw-bold bad-script-regular" style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </section>
                    <section style={{display:bukaUndangan}}>
                        {/* BOX 1 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <p className='text-center fw-bold m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontFamily:"initial",fontSize:"23pt"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</p>
                            <h6 className='text-center fw-normal m-0 mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{width:"45%"}}><img src={imagePengantin1} className='img-fluid' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`10px 10px 25px -18px ${btnColorCD}`,borderLeft:[borderBoxLeft]}} alt="calon pengantin pria" /></td>
                                        <td style={{width:"5%"}}><div className="text-center bi bi-suit-heart-fill" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25px",color:[fntColor]}}></div></td>
                                        <td style={{width:"45%"}}><img src={imagePengantin2} className='img-fluid' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",minHeight:"210px",maxHeight:"210px",borderRadius:"30px",boxShadow:`-10px 10px 25px -18px ${btnColorCD}`,borderRight:[borderBoxRight]}} alt="calon pengantin Wanita" /></td>
                                    </tr>
                                </tbody>    
                            </table>
                            <div className="container-fluid">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:[namaPengantin1.length > 23 ?"22pt":"28pt"]}}>{namaPengantin1}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-75'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i>  <a href={`https://instagram.com/${namaSosmedPengantin1}`}>{namaSosmedPengantin1}</a></h6>
                                        <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{kelaminPengantin1} {urutanPengantin1} dari<br/>Bapak {namaBapakPengantin1} & Ibu {namaIbuPengantin1}</h6>
                                    </div>
                                    <div className="col-12 col-md-1"><div className="text-center fw-normal courgette-regular mt-2" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"25pt",color:[fntColor]}}>&</div></div>
                                    <div className="col-12 col-md-5">
                                        <p className='text-center fw-bold courgette-regular m-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:[namaPengantin2.length > 23 ?"22pt":"28pt"]}}>{namaPengantin2}</p>
                                        <h6 className='text-center fw-normal bad-script-regular opacity-75'  ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],marginBottom:"0"}}><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i> <a href={`https://instagram.com/${namaSosmedPengantin2}`}>{namaSosmedPengantin2}</a></h6>
                                        <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{kelaminPengantin2} {urutanPengantin2} dari<br/>Bapak {namaBapakPengantin2} & Ibu {namaIbuPengantin2}</h6>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100'/>
                        </section>
                        {/* BOX 2 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="px-3 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Akad Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{hariAkad}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{formatDateToIndonesian(tanggalAkad)}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{jamMulaiAkad} sd {jamAkhirAkad} {zonaWaktuAkad}</h6>
                                                    <a href={kalenderAkad} aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="px-3 pt-3">
                                                    <div className='bi bi-calendar2-event text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Resepsi Nikah</p>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{hariResepsi}</h6>
                                                    <h6 className='text-center fw-normal bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{formatDateToIndonesian(tanggalResepsi)}</h6>
                                                    <h6 className='text-center fw-normal pb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",marginTop:"-8px",color:[fntColor]}}>{jamMulaiResepsi} sd {jamAkhirResepsi} {zonaWaktuResepsi}</h6>
                                                    <a href={kalenderResepsi} aria-label="saveCalendar">
                                                        <button className="form form-control fw-bold mb-4 mt-3 px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                    </a>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 3 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <div className='bi bi-map text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                            <p className='text-center fw-bold pt-0 courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Alamat Nikah</p>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Akad Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{alamatAkad}</h6>
                                        <a href={linkMapsAlamatAkad} aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-1"></div>
                                    <div className="col-12 col-md-5 mt-4 mb-3">
                                        <h1 className='text-center fw-normal courgette-regular mb-0' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>Resepsi Nikah</h1>
                                        <h6 className='text-center fw-normal mb-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{alamatResepsi}</h6>
                                        <a href={linkMapsAlamatResepsi} aria-label="Google Maps">
                                            <button className="form form-control fw-bold px-3 bad-script-regular" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100'/>
                        </section>
                        {/* BOX 4 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center p-4">
                                        <div className="col-12 col-md-6 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <h6 className='text-center fw-normal mt-2 bad-script-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>{ayat}</h6>
                                                    <h2 className='text-center fw-bold pt-1 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor]}}>({surah})</h2>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' style={{marginTop:"-10px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 5 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className='col-12 col-lg-10 mb-5'>
                                        <div className='bi bi-images text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                        <p className='text-center fw-bold pt-0 mb-4 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Gallery Foto</p>
                                        <div className="container-fluid">
                                            <div className='rowes justify-content-center'>
                                                <div className="column">
                                                    <a href={imageGallery1} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery1} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery2} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery2} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery3} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery3} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                </div>
                                                <div className="column">
                                                    <a href={imageGallery4} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery4} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery5} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery5} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                    <a href={imageGallery6} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery6} className="img-fluid" alt="Foto" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",width:"100%",borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={bgImageBox1} className='w-100' />
                        </section>
                        {/* BOX 6 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                <div className="container-fluid">
                                    <div className="row justify-content-center py-4 px-2">
                                        <div className="col-12 col-md-10 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-credit-card text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Kado Pernikahan</p>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${fntColor}`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {namaBank1}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[fntColor]}}>An. {namaPemilikBank1}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontSize:"14pt"}} ref={inputNoRek1} defaultValue={norekBank1}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor]}}><i className="bi bi-copy fw-bold" onClick={salinRek1}></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-5 mb-4">
                                                            <div>
                                                                <div className="box p-4" ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${fntColor}`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                    <p className='fw-bold bad-script-regular' style={{color:[fntColor],textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {namaBank2}</p>
                                                                    <div className="row mb-4">
                                                                        <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontSize:"40px"}}></i></div>
                                                                        <div className="col-10"><p className='fw-bold mt-3 px-1 bad-script-regular' style={{color:[fntColor]}}>An. {namaPemilikBank2}</p></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <input type="text" id='noRek1' className="fw-bold bad-script-regular" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontSize:"14pt"}} ref={inputNoRek2} defaultValue={norekBank2}/>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor]}}><i className="bi bi-copy fw-bold" onClick={salinRek2}></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Beri Ucapan</p>
                                                    <form onSubmit={createUcapanWePernikahan} ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)"}}>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="nama" style={{color:[fntColor],fontSize:"13pt"}}>Nama</label>
                                                        <input type="text" id='nama' name='nama' className='form form-control mb-2 bad-script-regular' value={nama} onChange={(e)=>setNama(e.target.value)} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} required maxLength="100" />
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="status" style={{color:[fntColor],fontSize:"13pt"}}>Status</label>
                                                        <select className="form-select form mb-2 bad-script-regular" id="status" name='status' aria-label="status" value={status} onChange={(e)=>setStatus(e.target.value)} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} required>
                                                            <option defaultValue={"Saudara"}>- Pilih Status -</option>
                                                            <option value="Saudara">Saudara</option>
                                                            <option value="Atasan Kerja">Atasan Kerja</option>
                                                            <option value="Bawahan Kerja">Bawahan Kerja</option>
                                                            <option value="Dosen/Guru">Dosen/Guru</option>
                                                            <option value="Teman Kerja">Teman Kerja</option>
                                                            <option value="Teman Sekolah">Teman Sekolah</option>
                                                            <option value="Teman">Teman</option>
                                                        </select>
                                                        <label className='mx-2 fw-normal bad-script-regular' htmlFor="ucapan" style={{color:[fntColor],fontSize:"13pt"}}>Ucapan</label>
                                                        <input type="text" id='ucapan' name='ucapan' className='form form-control mb-4 bad-script-regular' value={ucapan} onChange={(e)=>setUcapan(e.target.value)} required style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} maxLength="300"/>
                                                        <button type="submit"  className="form form-control fw-bold mb-4 mt-5 bad-script-regular" style={{backgroundColor:[btnColor],padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],border:[borderBtn],fontSize:"15pt"}}><i className="bi bi-send"></i> Kirim</button>
                                                        <h6 className='text-center mt-3 bad-script-regular' style={{color:[fntColor]}}>{msgUcapan}</h6>
                                                    </form>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-5 my-4">
                                            <div className='box' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                <div className="p-3">
                                                    <div className='bi bi-chat-square-text text-center' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",fontSize:"30px",color:[fntColor]}}></div>
                                                    <p className='text-center fw-bold pt-0 pb-3 courgette-regular' ref={(el) => {if (el) {if (!Array.isArray(elementsRef.current[0])){elementsRef.current[0] = []}elementsRef.current[0].push(el)}}} style={{opacity:"0",transform: "scale(0.5)",color:[fntColor],fontSize:"30pt",marginTop:"-10px"}}>Ucapan Live</p>
                                                    <div style={{maxHeight:"400px",overflow:"auto"}}>
                                                        <table>
                                                            <tbody>
                                                                {dataUcapan.map((user,index)=>(
                                                                    <tr className='mt-2'>
                                                                        <td style={{width:"3%"}}><p><i className="bi bi-person-circle" style={{color:[fntColor],fontSize:"20px"}}></i></p></td>
                                                                        <td style={{width:"1%"}}><p> </p></td>
                                                                        <td style={{width:"32%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"11pt",fontWeight:"500"}}>{user.nama}</p></td>
                                                                        <td style={{width:"1%"}}><p> </p></td>
                                                                        <td style={{width:"63%"}}><p className=" bad-script-regular" style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"5px 10px 5px 10px",fontSize:"11pt",fontWeight:"500"}}>{user.ucapan}</p></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <img src={bgImageBox2} className='w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* BOX 7 */}
                        <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                            <p className='text-center fw-bold mt-4 mb-5 courgette-regular' style={{color:[fntColor],fontSize:"25pt"}}>Website By Webew.id</p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[fntColor],marginBottom:"-2px"}}>Image On Freepik</h3>
                            <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                                <a href={link1} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik1}</a>
                                <a href={link2} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik2}</a>
                                <a href={link3} className=" bad-script-regular" aria-label="By"style={{textAlign: "center",color: fntColor,fontSize: "10pt",textDecoration: "none",}}>{pemilik3}</a>
                            </p>
                            <h3 className='text-center fw-normal mt-4 courgette-regular' style={{color:[fntColor],marginBottom:"-2px"}}>Song On Youtube</h3>
                            <a href={link4} className="mb-4 bad-script-regular" aria-label="By" style={{display:"block",margin:"auto",textAlign:"center",color:[fntColor],fontSize:"10pt"}}>{musik}</a>
                            <img src={bgImageBox1} className='w-100' style={{marginBottom:"20px"}}/>
                        </section>
                        {jenisWePernikahan === "aglaonema" || jenisWePernikahan === "agapanthus" || jenisWePernikahan === "amaryllis" || jenisWePernikahan === "alamanda"
                            ?<div>{kupuKupu.map((className, index) => (
                                <img key={index} src={pajangan} className={className} />
                            ))}</div>
                            :<div>{daun.map((className, index) => (
                                <img key={index} src={pajangan} className={className} />
                            ))}</div>
                        }
                    </section>
                </section>
            </section>
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN HASIL
// EKSPOR
export default WePernikahanHasil